"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function errorDirective() {
    return {
        restrict: 'E',
        scope: {
            show: '=',
            template: '@',
            class: '@',
        },
        templateUrl: 'components/error/error.html',
        transclude: true,
        link: function (scope, _element, _attrs, _ctrl, $transclude) {
            $transclude(function (clone) {
                scope.showContent = clone.length > 0;
            });
            scope.showTemplate = !scope.showContent && !!scope.template;
            scope.showDefault = !scope.showContent && !scope.showTemplate;
        },
    };
}
exports.errorDirective = errorDirective;
