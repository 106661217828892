"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var basePath = 'smart-meter-booking';
exports.routes = {
    accountService: '/account-service',
    credit: '/credit',
    simplysmart: '/simplysmart',
    communities: '/communities',
    alreadySmart: '/already-smart',
    appointmentDetails: '/appointment-details',
    missingMeterDetails: '/missing-meter-details',
    propertyQuestions: function (fuelType) {
        return '/smart-eligibility/property-questions/' + fuelType;
    },
    availability: '/availability',
    eligibility: '/eligibility',
    availabilityOutcome: '/availability-outcome',
    product: '/product',
    smartMeterBookingSetup: "/" + basePath,
    smartMeterBookingStart: "/" + basePath + "/start",
    smartMeterData: "/" + basePath + "/smart-meter-data",
    homeDetails: "/" + basePath + "/home-details",
    confirmation: "/" + basePath + "/confirmation",
    confirmationConfirmation: "/" + basePath + "/confirmation-confirmation",
    cancellation: "/" + basePath + "/cancellation",
    cancellationConfirmation: "/" + basePath + "/cancellation-confirmation",
};
// TODO: this shouldn't be a separate structure than the routes, but ATM is the easiest and safest solution
// the 2 structures need to be merged, though
// https://smart-uat.ovoenergy.com/smart-meter-booking/
// https://smb.devices-uat.ovoenergy.com/smart-meter-booking/
exports.titles = {
    accountService: 'Account-Service',
    credit: 'SMB-Login',
    simplysmart: 'SMB-SimplySmart',
    communities: 'SMB-Communities',
    alreadySmart: 'SMB-AlreadySmart',
    appointmentDetails: 'SMB-AppointmentDetails',
    missingMeterDetails: 'SMB-MissingMeterDetails',
    proprtyQuestionsFuelDual: 'SMB-PropertyQuestionFuelDual',
    proprtyQuestionsFuelElectricity: 'SMB-PropertyQuestionFuelElectricity',
    availability: 'SMB-Availability',
    eligibility: 'SMB-Eligibility',
    availabilityOutcome: 'SMB-AvailabilityOutcome',
    product: 'SMB-Product',
    smartMeterBookingSetup: "SMB-SmartMeterBooking",
    smartMeterBookingStart: "SMB-Start",
    smartMeterData: "SMB-SmartMeterData",
    homeDetails: "SMB-HomeDetails",
    confirmation: "SMB-Confirmation",
    confirmationConfirmation: "SMB-ConfirmationConfirmation",
    cancellation: "SMB-Cancellation",
    cancellationConfirmation: "SMB-CancellationConfirmation",
};
var SmbRoutesService = /** @class */ (function () {
    function SmbRoutesService() {
    }
    SmbRoutesService.prototype.getRoutes = function () {
        return exports.routes;
    };
    SmbRoutesService.prototype.isLocationSmbSetup = function (location) {
        return location === exports.routes.smartMeterBookingSetup;
    };
    SmbRoutesService.prototype.isLocationPartOfBookingFlow = function (location) {
        return (location === exports.routes.smartMeterBookingStart ||
            location === exports.routes.smartMeterData ||
            location === exports.routes.homeDetails ||
            location === exports.routes.confirmation);
    };
    return SmbRoutesService;
}());
exports.SmbRoutesService = SmbRoutesService;
