"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
SmartMeterDataConfig.$inject = ['$routeProvider'];
function SmartMeterDataConfig($routeProvider) {
    var routeSmartMeterData = {};
    routeSmartMeterData.templateUrl = 'views/smart-meter-data/smart-meter-data.html';
    routeSmartMeterData.controller = 'SmartMeterDataController';
    $routeProvider.when('/smart-meter-booking/smart-meter-data', routeSmartMeterData);
}
exports.SmartMeterDataConfig = SmartMeterDataConfig;
