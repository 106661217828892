"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function SmbValidationFactory() {
    function validateEmailAddress(emailAddress) {
        return emailAddress.match(/^[^\s]+@[^\s]+\.[^\s]+$/);
    }
    function validatePhoneNumber(phoneNumber) {
        var regexp = /^(\+44|0044|0)\d{9,10}$/;
        var phoneNumberWithoutSpaces = phoneNumber.replace(/\s/g, '');
        return regexp.test(phoneNumberWithoutSpaces);
    }
    return {
        validateEmailAddress: validateEmailAddress,
        validatePhoneNumber: validatePhoneNumber,
    };
}
exports.SmbValidationFactory = SmbValidationFactory;
