"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var moment = require("moment");
var types_1 = require("../../types");
var feature_flag_service_1 = require("../feature-flag/feature-flag.service");
eligibilityService.$inject = ['HttpClient', 'AppConfig', 'eligibilityState', 'localStorageService', 'featureFlag'];
function eligibilityService(HttpClient, AppConfig, eligibilityState, localStorageService, featureFlag) {
    var httpClient = new HttpClient(AppConfig.serviceBaseUrl);
    function toQueryString(obj) {
        var keyValues = _.map(obj, function (value, key) {
            return value ? key + '=' + encodeURIComponent(value) : undefined;
        });
        return _.filter(keyValues, function (kv) {
            return kv !== undefined;
        }).join('&');
    }
    function transformAppointmentsData(appointments) {
        if (!appointments || appointments.length === 0) {
            return appointments;
        }
        return _.reduce(appointments, function (result, appointment) {
            var date = moment(appointment.start).format('YYYY-MM-DD');
            if (!result[date]) {
                result[date] = {
                    slotType: 'FourHour',
                    slots: [],
                };
            }
            var slot = {
                start: moment(appointment.start).format('HH:mm'),
                end: moment(appointment.end).format('HH:mm'),
                band: appointment.timeBand,
            };
            result[date].slots.push(slot);
            return result;
        }, {});
    }
    function getPaymDualFuelQuestions() {
        if (featureFlag.isFeatureActive(feature_flag_service_1.USE_5TH_TERMINAL_FLOW)) {
            return {
                SMETS1: {
                    storageHeaters: { yes: 'notEligible', no: 'sameFloor', unknown: 'sameFloor' },
                    sameFloor: { yes: 'eligible', no: 'flat', unknown: 'flat' },
                    flat: { yes: 'notEligible', no: 'eligible', unknown: 'eligible' },
                },
                SMETS2: {
                    storageHeaters: { yes: 'sameFloor', no: 'sameFloor', unknown: 'sameFloor' },
                    sameFloor: { yes: 'eligible', no: 'flat', unknown: 'flat' },
                    flat: { yes: 'notEligible', no: 'eligible', unknown: 'eligible' },
                },
            };
        }
        else {
            return {
                SMETS1: {
                    sameFloor: { yes: 'eligible', no: 'flat', unknown: 'flat' },
                    flat: { yes: 'notEligible', no: 'eligible', unknown: 'eligible' },
                },
                SMETS2: {
                    sameFloor: { yes: 'eligible', no: 'flat', unknown: 'flat' },
                    flat: { yes: 'notEligible', no: 'eligible', unknown: 'eligible' },
                },
            };
        }
    }
    function getPaymSingleFuelQuestions() {
        if (featureFlag.isFeatureActive(feature_flag_service_1.USE_5TH_TERMINAL_FLOW)) {
            return {
                SMETS1: {
                    storageHeaters: { yes: 'notEligible', no: 'eligible', unknown: 'eligible' },
                },
                SMETS2: {
                    storageHeaters: { yes: 'eligible', no: 'eligible', unknown: 'eligible' },
                },
            };
        }
        else {
            return {
                SMETS1: {
                    storageHeaters: { yes: 'notEligible', no: 'eligible', unknown: 'eligible' },
                },
                SMETS2: {
                    storageHeaters: { yes: 'notEligible', no: 'eligible', unknown: 'eligible' },
                },
            };
        }
    }
    function getPropertyQuestions() {
        var wanCoverage = localStorageService.get('wanCoverage');
        var smartMeterType = wanCoverage === 'NoCoverage' ? types_1.SmartTypes.SMETS1 : types_1.SmartTypes.SMETS2;
        return eligibilityState.isDualFuel()
            ? getPaymDualFuelQuestions()[smartMeterType]
            : getPaymSingleFuelQuestions()[smartMeterType];
    }
    var service = {
        initialise: function (initObj) {
            eligibilityState.postcode.set(initObj.postcode);
            eligibilityState.mpan.set(initObj.mpan);
            eligibilityState.mprn.set(initObj.mprn);
            eligibilityState.fuelType.set(initObj.fuelType);
            eligibilityState.jobType.set(initObj.jobType);
            eligibilityState.accountId.set(initObj.accountId);
        },
        isSmartMeterBookingEnabled: function () {
            return AppConfig.isSmartMeterBookingEnabled && AppConfig.siteId === 'ovoenergy.com';
        },
        getAppointmentAvailability: function (smartType) {
            var fuel;
            if (this.isDualFuel()) {
                fuel = 'ElectricityGas';
            }
            else {
                fuel = 'Electricity';
            }
            var queryParams = toQueryString({
                postcode: eligibilityState.postcode.get(),
                mpan: eligibilityState.mpan.get(),
                fuelType: fuel,
                retailerCode: 'OVO',
                mprn: eligibilityState.mprn.get(),
                jobType: eligibilityState.jobType.get(),
                accountId: eligibilityState.accountId.get(),
                smartType: smartType,
            });
            return httpClient
                .get(AppConfig.smeAvailabilityUrl + '?' + queryParams)
                .then(function (availabilityData) {
                return _.assign({}, availabilityData, {
                    availableAppointments: transformAppointmentsData(availabilityData.availableAppointments),
                });
            });
        },
        isPropertyEligible: function () {
            return !!eligibilityState.property.get();
        },
        setPropertyEligibility: function (value) {
            eligibilityState.property.set(value);
        },
        setInFlat: function (value) {
            eligibilityState.inFlat.set(value);
        },
        setStorageHeaters: function (value) {
            eligibilityState.storageHeaters.set(value);
        },
        getStorageHeaters: function () {
            return eligibilityState.storageHeaters.get();
        },
        setMetersDiffFloor: function (value) {
            eligibilityState.metersDiffFloor.set(value);
        },
        isDualFuel: function () {
            return eligibilityState.isDualFuel();
        },
        isMpnAvailable: function () {
            return eligibilityState.isMpnAvailable();
        },
        getMpan: function () {
            return eligibilityState.mpan.get();
        },
        getMprn: function () {
            return eligibilityState.mprn.get();
        },
        getPostcode: function () {
            return eligibilityState.postcode.get();
        },
        isSmartMeterRequired: function () {
            return eligibilityState.required.get();
        },
        getJobType: function () {
            return eligibilityState.jobType.get();
        },
        setAvailability: function (smartType, value) {
            eligibilityState.setAvailability(smartType, value);
        },
        getAvailability: function (smartType) {
            return eligibilityState.getAvailability(smartType);
        },
        getPropertyQuestionResult: function (currentQuestion, answer) {
            var questions = getPropertyQuestions();
            return questions[currentQuestion][answer];
        },
        getFirstPropertyQuestion: function () {
            if (featureFlag.isFeatureActive(feature_flag_service_1.USE_5TH_TERMINAL_FLOW)) {
                return 'storageHeaters';
            }
            else {
                return eligibilityState.isDualFuel() ? 'sameFloor' : 'storageHeaters';
            }
        },
    };
    return service;
}
exports.eligibilityService = eligibilityService;
