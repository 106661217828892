"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var BookingFlowService = /** @class */ (function () {
    function BookingFlowService() {
        this.date = { value: null };
        this.timeOfDay = null;
        this.appointmentData = null;
        this.smartData = null;
        this.isBlocked = null;
        this.homeDetails = {
            name: null,
            phone: null,
            email: null,
        };
        this.access = {
            parking: null,
            other: null,
        };
        this.isConfirmed = false;
        this.isProvisional = false;
        this.hasElectricalMedicalEquipment = false;
        // 'dirty' fields tracks if value of field is not its default value but value submitted by user and should be stored in smb flow state
        this.hasElectricalMedicalEquipmentDirty = false;
        this.vulnerabilityCode = null;
        this.showVulnerabilityQuestion = false;
        this.planType = null;
        this.confirmedStartDate = null;
        this.confirmedEndDate = null;
        this.isIhdSelected = false;
        this.isIhdSelectedDirty = false;
        this.isReschedule = false;
        this.flexibilityStatus = 'No';
        this.flexibilityStatusDirty = false;
        this.loading = false;
        this.error = null;
        this.smbId = null;
    }
    BookingFlowService.formatTimeOfDay = function (timeOfDay) {
        switch (timeOfDay) {
            case 'Band08To12':
                return '8am - 12pm';
            case 'Band10To14':
                return '10am - 2pm';
            case 'Band12To16':
                return '12pm - 4pm';
            case 'Band14To18':
                return '2pm - 6pm';
            default:
                return '-';
        }
    };
    BookingFlowService.convertToTimeOfDay = function (confirmedStartDate) {
        switch (confirmedStartDate.hours()) {
            case 8:
                return 'Band08To12';
            case 10:
                return 'Band10To14';
            case 12:
                return 'Band12To16';
            case 14:
                return 'Band14To18';
            default:
                throw new Error('Unknown start time for appointment: ' + confirmedStartDate.toLocaleString());
        }
    };
    BookingFlowService.prototype.getTimeOfDayFormatted = function () {
        return BookingFlowService.formatTimeOfDay(this.timeOfDay);
    };
    BookingFlowService.prototype.resetDateTime = function () {
        this.date = { value: null };
        this.timeOfDay = null;
    };
    return BookingFlowService;
}());
exports.BookingFlowService = BookingFlowService;
