"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var types_1 = require("../../types");
EligibilityState.$inject = ['localStorageService'];
function EligibilityState(localStorageService) {
    function fetchFromLocalStorage() {
        return (localStorageService.get('propertySmartEligibility') || {
            postcode: undefined,
            mpan: undefined,
            mprn: undefined,
            fuelType: undefined,
            isPropertyOk: false,
            jobType: undefined,
            accountId: undefined,
            inFlat: undefined,
            storageHeaters: undefined,
            metersDiffFloor: undefined,
        });
    }
    function update(name, value) {
        var localStorageObject = fetchFromLocalStorage();
        localStorageObject[name] = value;
        localStorageService.set('propertySmartEligibility', localStorageObject);
    }
    function getProperty(name) {
        return {
            set: function (value) {
                update(name, value);
            },
            get: function () {
                return fetchFromLocalStorage()[name];
            },
        };
    }
    function getSmartTypeKey(smartType) {
        return smartType === types_1.SmartTypes.SMETS1 ? 'smartAvailability' : 'smets2Availability';
    }
    return {
        postcode: getProperty('postcode'),
        mpan: getProperty('mpan'),
        mprn: getProperty('mprn'),
        fuelType: getProperty('fuelType'),
        property: getProperty('isPropertyOk'),
        required: getProperty('required'),
        jobType: getProperty('jobType'),
        accountId: getProperty('accountId'),
        inFlat: getProperty('inFlat'),
        storageHeaters: getProperty('storageHeaters'),
        metersDiffFloor: getProperty('metersDiffFloor'),
        isMpnAvailable: function () {
            return !!(!!this.mpan.get() && (this.isSingleFuel() || !!this.mprn.get()));
        },
        isDualFuel: function () {
            return !!this.fuelType.get().match(/dual/i);
        },
        isSingleFuel: function () {
            return !this.isDualFuel();
        },
        setAvailability: function (smartType, value) {
            if (!lodash_1.includes(['High', 'Low', 'None', undefined], value)) {
                throw new Error('Invalid value');
            }
            var key = getSmartTypeKey(smartType);
            localStorageService.set(key, value);
        },
        getAvailability: function (smartType) {
            var key = getSmartTypeKey(smartType);
            var value = localStorageService.get(key) || undefined;
            if (value === 'undefined') {
                return undefined;
            }
            return value;
        },
        clearState: function () {
            localStorageService.set('propertySmartEligibility', {});
        },
    };
}
exports.EligibilityState = EligibilityState;
