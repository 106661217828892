"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var angular = require("angular");
var device_factory_1 = require("./device-factory/device-factory");
var http_client_1 = require("./http-client/http-client");
var match_height_1 = require("./match-height/match-height");
var mixpanel_1 = require("./mixpanel/mixpanel");
var modal_service_1 = require("./modal-service/modal-service");
var navigate_1 = require("./navigate/navigate");
var smb_validation_1 = require("./smb-validation/smb-validation");
var version_compare_1 = require("./version-compare/version-compare");
var pound_currency_filter_1 = require("./filters/pound-currency.filter");
angular
    .module('ovo.smb.utils', [])
    .directive('matchHeight', match_height_1.matchHeightDirective)
    .factory('Device', device_factory_1.DeviceFactory)
    .factory('HttpClient', http_client_1.HttpClientFactory)
    .factory('mixpanel', mixpanel_1.mixpanelService)
    .factory('SmbValidation', smb_validation_1.SmbValidationFactory)
    .factory('versionCompare', version_compare_1.VersionCompare)
    .filter('poundCurrency', pound_currency_filter_1.poundCurrencyFilter)
    .service('smbModalService', modal_service_1.SmbModalService)
    .service('smbNavigate', navigate_1.NavigateService);
