"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
HomeDetailsController.$inject = [
    '$scope',
    'smbNavigate',
    'SmbRoutesService',
    '$location',
    'bookingFlowService',
    'SmbEventReporter',
    'smbModalService',
    'SmbValidation',
    '$anchorScroll',
];
function HomeDetailsController($scope, navigate, smbRoutesService, $location, bookingFlowService, smbEventReporter, smbModalService, smbValidation, $anchorScroll) {
    $scope.bookingFlow = bookingFlowService;
    $scope.smbRoutes = smbRoutesService.getRoutes();
    if ($scope.bookingFlow.appointmentData && ($scope.bookingFlow.isConfirmed || $scope.bookingFlow.isProvisional)) {
        $location.replace();
        $location.url($scope.smbRoutes.confirmationConfirmation);
    }
    if (!$scope.bookingFlow.appointmentData || !$scope.bookingFlow.smbId) {
        $location.replace();
        $location.url($scope.smbRoutes.smartMeterBookingStart);
    }
    $scope.validatePhoneNumber = (function () {
        return {
            test: function (value) {
                return smbValidation.validatePhoneNumber(value);
            },
        };
    })();
    $scope.openWarningModal = function () {
        $scope.title = 'Important';
        $scope.url = 'views/modals/blocking-warn.html';
        smbModalService.open($scope, 'warning');
    };
    $scope.nextPage = function (dataIsSet, formController) {
        if (!dataIsSet) {
            $anchorScroll('meters-accessibility');
            return;
        }
        if (formController && formController.$invalid) {
            $anchorScroll('home-details-name');
            return;
        }
        navigate.to($scope.smbRoutes.confirmation);
    };
    $scope.prevPage = function () { return $scope.smbRoutes.smartMeterData; };
    $scope.editInstallationDate = function () {
        navigate.to($scope.smbRoutes.smartMeterBookingStart);
    };
    smbEventReporter.viewedScreen();
    $anchorScroll();
}
exports.HomeDetailsController = HomeDetailsController;
