"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function smeView() {
    return {
        restrict: 'EA',
        replace: true,
        transclude: true,
        templateUrl: 'layout/sme-view/sme-view.html',
    };
}
exports.smeView = smeView;
