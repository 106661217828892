"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var angular = require("angular");
var smb_app_1 = require("./smb-app/smb-app");
var smb_layout_1 = require("./smb-layout/smb-layout");
var sme_footer_1 = require("./sme-footer/sme-footer");
var sme_view_1 = require("./sme-view/sme-view");
angular
    .module('ovo.smb.layout', [])
    .controller('FooterController', sme_footer_1.FooterController)
    .directive('smbApp', smb_app_1.SmbApp)
    .directive('smbLayout', smb_layout_1.SmbLayout)
    .directive('smeView', sme_view_1.smeView);
