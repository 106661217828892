"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
ConfirmationConfig.$inject = ['$routeProvider'];
function ConfirmationConfig($routeProvider) {
    var routeConfirmation = {};
    routeConfirmation.templateUrl = 'views/confirmation/confirmation.html';
    routeConfirmation.controller = 'SmartMeterConfirmationController';
    $routeProvider.when('/smart-meter-booking/confirmation', routeConfirmation);
    var routeConfirmationConfirmation = {};
    routeConfirmationConfirmation.templateUrl = 'views/confirmation/confirmation-confirmation.html';
    routeConfirmationConfirmation.controller = 'SmartMeterConfirmationConfirmationController';
    $routeProvider.when('/smart-meter-booking/confirmation-confirmation', routeConfirmationConfirmation);
}
exports.ConfirmationConfig = ConfirmationConfig;
