"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var types_1 = require("../../types");
EligibilityController.$inject = [
    '$scope',
    'SmbRoutesService',
    'localStorageService',
    'smbNavigate',
    'bookingFlowState',
    'SmbService',
    'EventReporter',
    'eligibilityState',
];
function EligibilityController($scope, SmbRoutesService, localStorageService, navigate, bookingFlowState, SmbService, EventReporter, eligibilityState) {
    $scope.loading = true;
    $scope.eligibilityError = false;
    var smbId = localStorageService.get('smbId');
    var isAreaEligible = localStorageService.get('areaEligible') === 'true';
    var isMeterEligible = localStorageService.get('meterEligible') === 'true';
    var hasAbortedAppointment = localStorageService.get('hasAbortedAppointment') === 'true';
    var hasExistingAppointment = localStorageService.get('existingAppointment');
    var hasTwoRateElectricityTariff = localStorageService.get('hasTwoRateElectricityTariff');
    var propertyEligibility = localStorageService.get('propertySmartEligibility');
    var isOnSupply = localStorageService.get('notOnSupply') === 'false';
    var waitingForGasOnSupply = localStorageService.get('waitingForGasOnSupply') === 'true';
    var waitingForElecOnSupply = localStorageService.get('waitingForElecOnSupply') === 'true';
    var wanCoverage = localStorageService.get('wanCoverage');
    $scope.isOnSupply = isOnSupply;
    var eligibilityInfo = buildEligibilityInfo();
    bookingFlowState.eligibilityInfo.set(eligibilityInfo);
    if (isOnSupply) {
        SmbService.updateEligibilityInfo(smbId, eligibilityInfo)
            .then(function (eligibility) {
            var isPropertyEligible = eligibility.isEligible;
            $scope.isEligible = hasExistingAppointment || (isAreaEligible && isMeterEligible && isPropertyEligible);
            EventReporter.viewedScreen({
                'Area Eligible': isAreaEligible,
                'Meter Eligible': isMeterEligible,
                'Property Eligible': isPropertyEligible,
            }, 'eligibility');
            redirectToAvailabilityIfEligible();
        })
            .catch(function (error) {
            EventReporter.systemError('submit-eligibility-error', error.message);
            $scope.loading = false;
            $scope.eligibilityError = true;
        });
    }
    else {
        redirectToAvailabilityIfEligible();
    }
    function buildEligibilityInfo() {
        var eligibilityInfo = {};
        var smartMeterType = wanCoverage === 'HasCoverage' ? types_1.SmartTypes.SMETS2 : types_1.SmartTypes.SMETS1;
        eligibilityInfo.smartType = smartMeterType;
        if (propertyEligibility) {
            eligibilityInfo.storageHeaters = propertyEligibility.storageHeaters;
            eligibilityInfo.metersDiffFloor = propertyEligibility.metersDiffFloor;
            eligibilityInfo.inFlat = propertyEligibility.inFlat;
        }
        if (hasTwoRateElectricityTariff) {
            eligibilityInfo.twoRateElectricityTariff = hasTwoRateElectricityTariff === 'true';
        }
        var hasWanCoverageResponse = ['HasCoverage', 'NoCoverage'].includes(wanCoverage);
        if (hasWanCoverageResponse) {
            eligibilityInfo.wanCoverage = wanCoverage === 'HasCoverage';
        }
        return eligibilityInfo;
    }
    function redirectToAvailabilityIfEligible() {
        if ($scope.isEligible && $scope.isOnSupply) {
            navigate.to(SmbRoutesService.getRoutes().availability);
        }
        else {
            $scope.loading = false;
        }
    }
    function getIneligibilityMessage() {
        var message = "Sorry, we can't install a smart meter for you at the moment.";
        if (!isMeterEligible) {
            message = "Sorry, we can't install a smart meter for your current meter setup at the moment.";
        }
        else if (!isAreaEligible) {
            message = "Unfortunately we're not installing smart meters in your area just yet.";
        }
        else if (eligibilityState.inFlat && eligibilityState.inFlat.get() === true) {
            message =
                "Sorry, we can't install smart meters in your property at the moment as your electricity and gas meters are too far apart.";
        }
        else if (hasAbortedAppointment) {
            message = "It looks like you've still got an appointment.";
        }
        else {
            if (waitingForGasOnSupply && !waitingForElecOnSupply) {
                message = 'Your gas supply will take a little while to switch to OVO from your old supplier.';
            }
            else if (waitingForGasOnSupply || waitingForElecOnSupply) {
                message = "You're still switching to OVO from your old supplier.";
            }
            else if (!isOnSupply) {
                message = "Sorry, we can't install a smart meter for you at this time.";
            }
        }
        return message;
    }
    function getIneligibilityNextSteps() {
        var messageLine1 = "We're constantly improving our smart meter technology to fix this. We'll email you as soon as we can install them in your property.";
        if (!isAreaEligible) {
            messageLine1 =
                "We're working our way around the country and we'll email you as soon as we have engineers in your area.";
        }
        else if (hasAbortedAppointment) {
            messageLine1 =
                "We weren't able to complete your last appointment and some additional work may be needed before you can book a new one. We'll be in contact when we can rebook your smart meter installation.";
        }
        else {
            if (waitingForGasOnSupply && !waitingForElecOnSupply) {
                messageLine1 = "As soon as it's moved across we'll contact you to book your free smart meter installation.";
            }
            else if (waitingForGasOnSupply || waitingForElecOnSupply) {
                messageLine1 =
                    "As soon as your switch is complete we'll contact you to book your free smart meter installation.";
            }
            else if (!isOnSupply) {
                messageLine1 =
                    "It looks like you've switched one or more of your energy supplies to another provider. If this isn't the case, please get in contact with us.";
            }
        }
        return { line1: messageLine1 };
    }
    $scope.ineligibilityMessage = getIneligibilityMessage();
    var nextSteps = getIneligibilityNextSteps();
    $scope.ineligibilityNextSteps = nextSteps.line1;
    $scope.continue = function () { return navigate.to(SmbRoutesService.getRoutes().smartMeterBookingSetup); };
}
exports.EligibilityController = EligibilityController;
