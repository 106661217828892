"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
AppointmentDetailsConfig.$inject = ['$routeProvider'];
function AppointmentDetailsConfig($routeProvider) {
    var routeAppointmentDetails = {};
    routeAppointmentDetails.templateUrl = 'views/appointment-details/appointment-details.html';
    routeAppointmentDetails.controller = 'AppointmentDetailsController';
    $routeProvider.when('/appointment-details', routeAppointmentDetails);
}
exports.AppointmentDetailsConfig = AppointmentDetailsConfig;
