"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
bannerController.$inject = ['$scope', 'AppConfig', 'smbModalService'];
function bannerController($scope, AppConfig, smbModalService) {
    $scope.showBanner = AppConfig.showBanner;
    $scope.showBannerTerms = function () {
        $scope.url = 'views/modals/banner-terms.html';
        $scope.title = '£30 Credit Offer Terms & Conditions';
        smbModalService.open($scope, 'generic');
    };
}
exports.bannerController = bannerController;
function bannerDirective() {
    return {
        restrict: 'E',
        controller: bannerController,
        templateUrl: 'components/banner/banner.html',
    };
}
exports.bannerDirective = bannerDirective;
