"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
SmartMeterCancellationConfirmationController.$inject = [
    '$scope',
    'AppConfig',
    'smbNavigate',
    'SmbRoutesService',
    'bookingFlowService',
];
function SmartMeterCancellationConfirmationController($scope, AppConfig, navigate, smbRoutesService, bookingFlowService) {
    $scope.bookingFlow = bookingFlowService;
    $scope.bookAppointment = function () {
        $scope.bookingFlow.isReschedule = false;
        navigate.to(smbRoutesService.getRoutes().availability);
    };
    $scope.gotoMyOVO = function () {
        navigate.toExternalUrl(AppConfig.myOVOBaseUrl);
    };
}
exports.SmartMeterCancellationConfirmationController = SmartMeterCancellationConfirmationController;
