"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var angular = require("angular");
var _ = require("lodash");
calendarMiniContinuousDirective.$inject = ['$timeout'];
function calendarMiniContinuousDirective($timeout) {
    return {
        restrict: 'EA',
        replace: true,
        templateUrl: 'components/calendar-mini-continuous/calendar-mini-continuous.html',
        scope: {
            selectedDate: '=',
            selectableDates: '=',
        },
        link: function (scope, element) {
            scope.sortedSlots = _.sortBy(scope.selectableDates, function (asd) { return asd; });
            var firstSelectableDate = scope.sortedSlots[0];
            var lastSelectableDate = scope.sortedSlots[scope.sortedSlots.length - 1];
            scope.visibleSlots = [];
            scope.$watch('selectableDates', function (newVal) {
                newVal = newVal || [];
                scope.sortedSlots = _.sortBy(newVal, function (asd) { return asd; });
                scope.visibleSlots = calculateVisibleSlots();
            });
            scope.$watch('selectedDate.value', function () {
                scope.visibleSlots = calculateVisibleSlots();
            });
            function calculateVisibleSlots() {
                var slots = scope.sortedSlots;
                if (slots.length === 0) {
                    return slots;
                }
                var index = findSelectedIndex(slots);
                $timeout(function () {
                    var table = angular.element(element[0].querySelector('.calendarmini-table-alt'));
                    var coll = angular.element(element[0].querySelector('.calendarmini-table-col'));
                    if (coll.length && table.length) {
                        var x = -Math.max(index, 0) + 2;
                        x = x * coll[0].offsetWidth;
                        table.css('transform', 'translate3d(' + x + 'px, 0, 0)');
                    }
                });
                return slots;
            }
            function findSelectedIndex(slots) {
                return _.findIndex(slots, function (slot) { return slot && slot.isSame(scope.selectedDate.value, 'day'); });
            }
            scope.canGoPrevious = function () { return scope.selectedDate.value.isAfter(firstSelectableDate); };
            scope.canGoNext = function () { return scope.selectedDate.value.isBefore(lastSelectableDate); };
            scope.addDay = function (num) {
                if (num > 0 || scope.canGoPrevious()) {
                    var selectedIndex = findSelectedIndex(scope.sortedSlots) + num;
                    scope.selectedDate.value = scope.sortedSlots[selectedIndex];
                    scope.visibleSlots = calculateVisibleSlots();
                }
            };
            scope.isSelected = function (date) {
                return date && scope.selectedDate.value && scope.selectedDate.value.isSame(date, 'day');
            };
            scope.select = function (date) {
                if (date) {
                    scope.selectedDate.value = date.clone();
                }
            };
        },
    };
}
exports.calendarMiniContinuousDirective = calendarMiniContinuousDirective;
