"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
SmbAppMixpanelFactory.$inject = ['mixpanel', 'EventReporter'];
function SmbAppMixpanelFactory(mixpanel, EventReporter) {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    function noop() { }
    function setup() {
        EventReporter.addListener(raiseSmbMixpanelEvent);
    }
    function raiseSmbMixpanelEvent(eventType, eventName, properties) {
        (({
            'viewed-screen': function () {
                (({
                    identity: function () {
                        mixpanel.track('SMB - Viewed Identity Screen', properties);
                    },
                    question: function () {
                        mixpanel.track('SMB - Viewed Eligibility Question Screen', properties);
                    },
                    eligibility: function () {
                        mixpanel.track('SMB - Viewed Eligibility Outcome Screen', properties);
                    },
                    'account-service': function () {
                        mixpanel.track('SMB - Viewed Service Account Picker Screen', properties);
                    },
                    'missing-meter-details': function () {
                        mixpanel.track('SMB - Viewed Missing Meter Details Screen', properties);
                    },
                    'already-smart': function () {
                        mixpanel.track('SMB - Viewed Already Smart Screen', properties);
                    },
                    'appointment-details': function () {
                        mixpanel.track('SMB - Viewed Appointment Details Screen', properties);
                    },
                }[eventName] || noop)());
            },
            'system-error': function () {
                (({
                    'submit-identity-error': function () {
                        mixpanel.track('SMB - Submitted Identity Screen Error', properties);
                    },
                    'smb-setup-error': function () {
                        mixpanel.track('SMB - Smart Meter Booking Setup Error', properties);
                    },
                    'eligibility-error': function () {
                        mixpanel.track('SMB - Eligibility Outcome Error', properties);
                    },
                    'availability-not-present': function () {
                        mixpanel.track('SMB - Get Availability Call Failed', properties);
                    },
                }[eventName] || noop)());
            },
            'generic-event': function () {
                (({
                    'submit-identity': function () {
                        if (properties['Energy Global Account ID']) {
                            mixpanel.identify(properties['Energy Global Account ID']);
                        }
                        mixpanel.people.set({
                            'Customer Name': properties['Customer Name'],
                            'Email Address': properties['Email Address'],
                            Phone: properties.Phone,
                            'Address Line 1': properties['Address Line 1'],
                            'Address Line 2': properties['Address Line 2'],
                            'Post Town': properties['Post Town'],
                            'Energy Account ID': properties['Energy Account ID'],
                            'Energy Global Account ID': properties['Energy Global Account ID'],
                            Postcode: properties.Postcode,
                            'Energy Customer Type': properties['Energy Customer Type'],
                        });
                        mixpanel.track('SMB - Submitted Identity Screen', {
                            'Area Eligible': properties['Area Eligible'],
                            'Meter Eligible': properties['Meter Eligible'],
                            'Smart Meter Required': properties['Smart Meter Required'],
                            'Is IGT': properties['Is IGT'],
                            'Has Existing Appointment': properties['Has Existing Appointment'],
                        });
                    },
                    'submit-question': function () {
                        mixpanel.track('SMB - Submitted Eligibility Question', properties);
                    },
                }[eventName] || noop)());
            },
        }[eventType] || noop)());
    }
    return {
        setup: setup,
        raiseSmbMixpanelEvent: raiseSmbMixpanelEvent,
    };
}
exports.SmbAppMixpanelFactory = SmbAppMixpanelFactory;
