"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function SmbLayout() {
    return {
        restrict: 'E',
        transclude: true,
        replace: true,
        templateUrl: 'layout/smb-layout/smb-layout.html',
    };
}
exports.SmbLayout = SmbLayout;
