"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.beyondBanner = {
    templateUrl: 'components/beyond-banner/beyond-banner.html',
    bindings: {
        baseUrl: '@',
    },
};
exports.beyondBannerFootnotes = {
    templateUrl: 'components/beyond-banner/beyond-banner-footnotes.html',
    bindings: {
        classModifier: '@',
    },
};
