"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var UtmParamProvider = /** @class */ (function () {
    function UtmParamProvider() {
        this.paramConfig = {
            parameters: '',
        };
    }
    UtmParamProvider.prototype.preserveUtmParameters = function (queryParams) {
        var utmParams = queryParams
            .split(/(&)/i)
            .filter(function (param) { return param.startsWith('utm_'); })
            .join('&');
        if (utmParams !== '') {
            this.paramConfig.parameters = '&'.concat(utmParams);
        }
    };
    UtmParamProvider.prototype.$get = function () {
        return this.paramConfig;
    };
    return UtmParamProvider;
}());
exports.UtmParamProvider = UtmParamProvider;
