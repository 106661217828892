"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
IdentityConfig.$inject = ['$routeProvider'];
// TODO: Please clean this mess, too many repetitions to achieve the same goal
function IdentityConfig($routeProvider) {
    var routeIdentity = {};
    routeIdentity.templateUrl = 'views/identity/identity.html';
    routeIdentity.controller = 'IdentityController';
    routeIdentity.pageTitle = 'Identity';
    routeIdentity.caseInsensitiveMatch = true;
    routeIdentity.resolve = {
        planType: function () {
            return {
                paymentMethod: 'Credit',
                tariff: 'Credit',
                plan: 'Credit',
            };
        },
        jobType: function () {
            return 'Credit';
        },
    };
    $routeProvider.when('/credit', routeIdentity);
    var routeSimplySmart = {};
    routeSimplySmart.templateUrl = 'views/identity/identity.html';
    routeSimplySmart.controller = 'IdentityController';
    routeSimplySmart.pageTitle = 'Identity';
    routeSimplySmart.caseInsensitiveMatch = true;
    routeSimplySmart.resolve = {
        planType: function () {
            return {
                paymentMethod: 'Credit',
                tariff: 'Credit',
                plan: 'SimplySmart',
            };
        },
        jobType: function () {
            return 'Credit';
        },
    };
    $routeProvider.when('/simplysmart', routeSimplySmart);
    var routeCommunities = {};
    routeCommunities.templateUrl = 'views/identity/identity.html';
    routeCommunities.controller = 'IdentityController';
    routeCommunities.pageTitle = 'Identity';
    routeCommunities.caseInsensitiveMatch = true;
    routeCommunities.resolve = {
        planType: function () {
            return {
                paymentMethod: 'Credit',
                tariff: 'Credit',
                plan: 'Credit',
            };
        },
        jobType: function () {
            return 'Credit';
        },
    };
    $routeProvider.when('/communities', routeCommunities);
}
exports.IdentityConfig = IdentityConfig;
