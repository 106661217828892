"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
SmbApp.$inject = ['smbAppMixpanel', 'smbMixpanel'];
function SmbApp(smbAppMixpanel, smbMixpanel) {
    smbAppMixpanel.setup();
    smbMixpanel.setup();
    smbAppController.$inject = ['$scope', 'bookingFlowState', 'AppConfig'];
    return {
        restrict: 'EA',
        templateUrl: 'layout/smb-app/smb-app.html',
        controller: smbAppController,
    };
    function smbAppController($scope, bookingFlowState, AppConfig) {
        $scope.assetsBaseUrl = AppConfig.assetsBaseUrl;
        $scope.$on('LocalStorageModule.notification.setitem', function () {
            $scope.userName = bookingFlowState.customerName.get();
        });
        $scope.gotoMyOVO = function () { return AppConfig.myOVOBaseUrl; };
        $scope.goToMyProfile = function () { return AppConfig.myOVOBaseUrl + "/my-profile"; };
    }
}
exports.SmbApp = SmbApp;
