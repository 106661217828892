"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
AccountServiceController.$inject = ['EventReporter', 'AppConfig', 'utmParam'];
function AccountServiceController(EventReporter, AppConfig, utmParam) {
    EventReporter.viewedScreen();
    // Redirect user off application to the account picker service:
    //    - Service determines if you are orion or apollo
    //    - Orion users are sent to the orion smart booking application (ose-booking)
    //    - Apollo users are redirected back here but with "/credit" appended to URL to begin booking flow
    //    - This can't be done in a local dev environment so we can fake success by going to "/credit" manually
    if (AppConfig.accountServiceRedirectUrl) {
        location.href = AppConfig.accountServiceRedirectUrl + encodeURIComponent(utmParam.parameters);
    }
    else {
        // Fallback in case of bad config
        location.pathname = '/credit';
    }
}
exports.AccountServiceController = AccountServiceController;
