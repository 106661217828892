"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
SmartMeterBookingSetupController.$inject = [
    '$scope',
    '$routeParams',
    'SmbRoutesService',
    'smbNavigate',
    'bookingFlowState',
    'bookingFlowService',
    'localStorageService',
    'SmbService',
    'eligibilityService',
    'SmartMeterTypeService',
];
function SmartMeterBookingSetupController($scope, $routeParams, SmbRoutesService, navigate, bookingFlowState, bookingFlowService, localStorageService, SmbService, eligibilityService, smartMeterTypeService) {
    $scope.bookingFlowService = bookingFlowService;
    $scope.loadData = function () {
        $scope.loading = true;
        if ($routeParams.smbId) {
            localStorageService.set('smbId', $routeParams.smbId);
        }
        var smbId = localStorageService.get('smbId');
        if (smbId) {
            SmbService.getSmartMeterBookingData(smbId)
                .then(function (smbData) {
                setBookingFlowData(smbData, smbId);
                if (!smbData.hasRequiredMpxnDetails) {
                    $scope.loading = false;
                    navigate.to(SmbRoutesService.getRoutes().missingMeterDetails);
                }
                else if (smbData.isBooked) {
                    $scope.loading = false;
                    navigate.to(SmbRoutesService.getRoutes().smartMeterBookingStart);
                }
                else {
                    var smartType = smartMeterTypeService.getSmartMeterType();
                    var availability = eligibilityService.getAvailability(smartType);
                    if (availability === 'High' || availability === 'Low') {
                        $scope.loading = false;
                        navigate.to(SmbRoutesService.getRoutes().smartMeterBookingStart);
                    }
                    else if (availability === 'None') {
                        $scope.loading = false;
                        navigate.to(SmbRoutesService.getRoutes().availabilityOutcome);
                    }
                    else {
                        navigate.to(SmbRoutesService.getRoutes().availability);
                        $scope.loading = false;
                    }
                }
            })
                .catch(onError);
        }
        else {
            $scope.loading = false;
            navigate.to(SmbRoutesService.getRoutes().eligibility);
        }
    };
    function onError() {
        $scope.smbSetupError = 'Sorry, but an error has occurred while retrieving your details.';
        $scope.loading = false;
    }
    function setBookingFlowData(data, smbId) {
        $scope.bookingFlowService.smbId = smbId;
        bookingFlowState.customerName.set(data.customerName);
        bookingFlowState.emailAddress.set(data.emailAddress);
        bookingFlowState.phoneNumber.set(data.phoneNumber);
        bookingFlowState.planType.set(data.planType);
        $scope.bookingFlowService.homeDetails = {
            name: data.customerName,
            phone: data.phoneNumber,
            email: data.emailAddress,
        };
        $scope.bookingFlowService.planType = data.planType.plan;
    }
    $scope.loadData();
}
exports.SmartMeterBookingSetupController = SmartMeterBookingSetupController;
