"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
SmartEligibilityAvailabilityConfig.$inject = ['$routeProvider'];
function SmartEligibilityAvailabilityConfig($routeProvider) {
    var routeSmartEligibilityAvailability = {};
    routeSmartEligibilityAvailability.templateUrl = 'views/availability/availability.html';
    routeSmartEligibilityAvailability.controller = 'SmartAvailabilityController';
    routeSmartEligibilityAvailability.controllerAs = 'ctrl';
    $routeProvider.when('/availability', routeSmartEligibilityAvailability);
    var routeAvailabilityOutcome = {};
    routeAvailabilityOutcome.templateUrl = 'views/availability-outcome/availability-outcome.html';
    $routeProvider.when('/availability-outcome', routeAvailabilityOutcome);
}
exports.SmartEligibilityAvailabilityConfig = SmartEligibilityAvailabilityConfig;
