"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var angular = require("angular");
require("angular-animate");
require("angular-cookies");
require("angular-local-storage");
require("angular-route");
require("angular-sanitize");
require("angular-scroll");
require("angular-shims-placeholder/dist/angular-shims-placeholder");
require("angular-strap/dist/angular-strap");
require("angular-strap/dist/angular-strap.tpl");
require("angulartics");
require("angulartics-google-tag-manager");
require("bootstrap");
require("./components/components.module");
require("./components/directives.module");
require("./layout/layout.module");
require("./models/models.module");
require("./services/services.module");
require("./utils/utils.module");
require("./views/views.module");
var routing_config_1 = require("./routing-config");
var run_1 = require("./run");
angular
    .module('ovo.smb', [
    'duScroll',
    'ngRoute',
    'ngAnimate',
    'ngCookies',
    'ngSanitize',
    'mgcrea.ngStrap',
    'LocalStorageModule',
    'ng.shims.placeholder',
    'angulartics',
    'angulartics.google.tagmanager',
    'ovo.smb.components',
    'ovo.smb.directives',
    'ovo.smb.layout',
    'ovo.smb.models',
    'ovo.smb.services',
    'ovo.smb.utils',
    'ovo.smb.views',
])
    .config(routing_config_1.RoutingConfig)
    .run(run_1.Run);
// This module is most likely addressed by the index.html file served by the backend.
// So until that file is served by the BE code, this should stay here
// TODO: Remove this module and use ovo.smb as entry point
angular.module('ovo.smb.main', ['ovo.smb']).config(ConfigSmbMain);
ConfigSmbMain.$inject = ['$routeProvider', '$logProvider', '$coreProvider', 'smbConfigProvider', 'utmParamProvider'];
function ConfigSmbMain($routeProvider, $logProvider, $coreProvider, smbConfigProvider, utmParamProvider) {
    $logProvider.debugEnabled(true);
    $coreProvider.isCustomerPortal(false);
    if (AppConfig.siteId !== 'ovoenergy.com') {
        $coreProvider.setLogoSvg('');
        $coreProvider.setLogoStickySvg('');
    }
    // Redirect to account picker to get user on correct system (orion vs apollo accounts)
    utmParamProvider.preserveUtmParameters(location.search.substring(1));
    $routeProvider.otherwise({ redirectTo: '/account-service' });
    smbConfigProvider.setServiceBaseUrl(AppConfig.serviceBaseUrl + '/api');
}
