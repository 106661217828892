"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
SmartMeterBookingSetupConfig.$inject = ['$routeProvider'];
function SmartMeterBookingSetupConfig($routeProvider) {
    var routeSmartMeterBookingSetup = {};
    routeSmartMeterBookingSetup.templateUrl = 'views/smart-meter-booking-setup/smart-meter-booking-setup.html';
    routeSmartMeterBookingSetup.controller = 'SmartMeterBookingSetupController';
    $routeProvider.when('/smart-meter-booking', routeSmartMeterBookingSetup);
}
exports.SmartMeterBookingSetupConfig = SmartMeterBookingSetupConfig;
