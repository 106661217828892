"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
RoutingConfig.$inject = ['$locationProvider'];
// ensure we can write route like http:/domain/users instead of http:/domain/#users
function RoutingConfig($locationProvider) {
    $locationProvider.html5Mode({
        enabled: true,
        requireBase: false,
    });
}
exports.RoutingConfig = RoutingConfig;
