"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var types_1 = require("../../types");
var feature_flag_service_1 = require("../../services/feature-flag/feature-flag.service");
IdentityController.$inject = [
    '$window',
    '$scope',
    '$routeParams',
    '$q',
    'SmbRoutesService',
    'smbNavigate',
    'EventReporter',
    'SmbService',
    'localStorageService',
    'bookingFlowState',
    'planType',
    'jobType',
    'eligibilityState',
    'optimizelyService',
    'featureFlag',
];
function IdentityController($window, $scope, $routeParams, $q, SmbRoutesService, navigate, EventReporter, SmbService, localStorageService, bookingFlowState, planType, jobType, eligibilityState, optimizelyService, featureFlag) {
    $scope.submitAndCheck = submitAndCheck;
    $scope.showAccountId = true;
    $scope.isNewLayout =
        featureFlag.isFeatureActive(feature_flag_service_1.NEW_LOGIN_PAGE) &&
            optimizelyService.activeVariationIs('SMB - Credit page layout', 'newLayout');
    function loadData() {
        $scope.identityError = false;
        $scope.loading = false;
        $scope.data = {
            accountNumber: '',
            postcode: '',
        };
        eligibilityState.clearState();
        localStorageService.clearAll();
        localStorageService.set('canSkipQuestions', $routeParams.canSkipQuestions === 'true');
        if ($routeParams.accountId && $routeParams.postcode) {
            // Use gtAccountNumber (gentrack ID) if provided, otherwise use the accountId
            if ($routeParams.gtAccountNumber) {
                $scope.data.accountNumber = $routeParams.gtAccountNumber;
            }
            else {
                $scope.data.accountNumber = $routeParams.accountId;
            }
            $scope.data.postcode = $routeParams.postcode;
            EventReporter.viewedScreen({ autofill: true }, 'identity');
            submitAndCheck();
        }
        else if ($routeParams.accountId) {
            // Use gtAccountNumber (gentrack ID) if provided, otherwise use the accountId
            if ($routeParams.gtAccountNumber) {
                $scope.data.accountNumber = $routeParams.gtAccountNumber;
            }
            else {
                $scope.data.accountNumber = $routeParams.accountId;
            }
            EventReporter.viewedScreen({ autofill: false }, 'identity');
            $scope.showAccountId = optimizelyService.activeVariationIs('SMB - Account Id', 'Shown');
        }
        else {
            EventReporter.viewedScreen({ autofill: false }, 'identity');
        }
    }
    function setUsabillaCustomVariables(accountNumber, postcode) {
        var usabillaLive = $window.usabilla_live;
        if (usabillaLive) {
            usabillaLive('data', {
                custom: {
                    'Account ID': accountNumber,
                    Postcode: postcode,
                },
            });
        }
    }
    function toQueryString(obj) {
        var keyValues = _.map(obj, function (value, key) { return (value ? key + '=' + encodeURIComponent(value) : undefined); });
        return _.filter(keyValues, function (keyValue) { return keyValue !== undefined; }).join('&');
    }
    function submitAndCheck() {
        var accountNumber = $scope.data.accountNumber;
        var postcode = $scope.data.postcode;
        setUsabillaCustomVariables(accountNumber, postcode);
        $scope.loading = true;
        SmbService.identifyAndCheck(accountNumber, postcode)
            .then(function (accountDetails) {
            if ((accountDetails.brand && accountDetails.brand === types_1.Brands.Lumo) ||
                (accountDetails.paymentMethod && accountDetails.paymentMethod !== types_1.PaymentMethods.Credit)) {
                optimizelyService.push.creditFormSubmission('error');
                return $q.reject(new Error('Non-OVO customer tried to login'));
            }
            $scope.loading = false;
            localStorageService.set('areaEligible', accountDetails.isAreaEligible);
            localStorageService.set('meterEligible', accountDetails.isMeterEligible);
            localStorageService.set('hasTwoRateElectricityTariff', accountDetails.hasTwoRateElectricityTariff);
            localStorageService.set('hasAbortedAppointment', accountDetails.hasAbortedAppointment);
            localStorageService.set('existingAppointment', accountDetails.existingAppointment);
            localStorageService.set('smbId', accountDetails.smbId);
            localStorageService.set('globalAccountId', accountDetails.globalCustomerAccountId);
            var notOnSupply = (accountDetails.hasGas && accountDetails.supplyStatus.gas !== 'CURRENT') ||
                accountDetails.supplyStatus.electricity !== 'CURRENT';
            var waitingForGasOnSupply = accountDetails.hasGas &&
                (accountDetails.supplyStatus.gas === 'PENDING' || accountDetails.supplyStatus.gas === 'FUTURE');
            var waitingForElecOnSupply = accountDetails.supplyStatus.electricity === 'PENDING' || accountDetails.supplyStatus.electricity === 'FUTURE';
            localStorageService.set('notOnSupply', notOnSupply);
            localStorageService.set('waitingForGasOnSupply', waitingForGasOnSupply);
            localStorageService.set('waitingForElecOnSupply', waitingForElecOnSupply);
            bookingFlowState.customerName.set(accountDetails.customerName);
            bookingFlowState.emailAddress.set(accountDetails.emailAddress);
            bookingFlowState.phoneNumber.set(accountDetails.phoneNumber);
            bookingFlowState.addressDetails.set(accountDetails.addressDetails);
            bookingFlowState.fuelType.set(accountDetails.hasGas === true ? 'ElectricityGas' : 'Electricity');
            bookingFlowState.planType.set(planType);
            bookingFlowState.accountId.set($scope.data.accountNumber);
            bookingFlowState.mpan.set(accountDetails.mpan);
            bookingFlowState.mprn.set(accountDetails.mprn);
            bookingFlowState.elecMsn.set(accountDetails.elecMsn);
            bookingFlowState.gasMsn.set(accountDetails.gasMsn);
            EventReporter.genericEvent('submit-identity', {
                'Customer Name': accountDetails.customerName,
                'Email Address': accountDetails.emailAddress,
                Phone: accountDetails.phoneNumber,
                'Energy Account ID': $scope.data.accountNumber,
                Postcode: $scope.data.postcode,
                'Address Line 1': accountDetails.addressDetails.addressLine1,
                'Address Line 2': accountDetails.addressDetails.addressLine2,
                'Post Town': accountDetails.addressDetails.postTown,
                'Area Eligible': accountDetails.isAreaEligible,
                'Meter Eligible': accountDetails.isMeterEligible,
                'Smart Meter Required': accountDetails.isSmartMeterRequired,
                'Has Existing Appointment': accountDetails.existingAppointment !== undefined,
                'Energy Customer Type': planType.plan,
                'Is IGT': accountDetails.isIGT,
            });
            if (!accountDetails.smbId) {
                SmbService.initialiseSmartMeterBooking()
                    .then(function (result) {
                    localStorageService.set('smbId', result.id);
                    navigateAfterIdentityPage(accountDetails);
                })
                    .catch(function (error) {
                    if (error.code === 'ClientError') {
                        localStorageService.set('existingAppointment', true);
                        navigate.to('/appointment-details');
                        optimizelyService.push.creditFormSubmission('alreadyAppointment');
                    }
                    else {
                        optimizelyService.push.creditFormSubmission('error');
                    }
                });
            }
            else {
                navigateAfterIdentityPage(accountDetails);
            }
        })
            .catch(function (error) {
            EventReporter.systemError('submit-identity-error', error.message, {
                'Energy Account ID': $scope.data.accountNumber,
                Postcode: $scope.data.postcode,
            });
            $scope.loading = false;
            $scope.identityError = true;
            optimizelyService.push.creditFormSubmission('error');
        });
    }
    function navigateAfterIdentityPage(accountDetails) {
        if (!accountDetails.isSmartMeterRequired || accountDetails.hasCompletedAppointment) {
            navigate.to(SmbRoutesService.getRoutes().alreadySmart);
            optimizelyService.push.creditFormSubmission('alreadySmart');
        }
        else {
            var propertyInfo = {
                mpan: accountDetails.mpan,
                mprn: accountDetails.mprn,
                postcode: $scope.data.postcode,
                jobType: jobType,
                accountId: $scope.data.accountNumber,
            };
            var fuelType = accountDetails.hasGas ? 'dual' : 'electricity';
            var url = SmbRoutesService.getRoutes().propertyQuestions(fuelType) + '?' + toQueryString(propertyInfo);
            var smbId = localStorageService.get('smbId');
            var isWanCheckEnabled = AppConfig.isWanCheckEnabled;
            if (isWanCheckEnabled) {
                SmbService.getWanCoverage(smbId).then(function (response) { return localStorageService.set('wanCoverage', response.wanCoverage); });
            }
            navigate.to(url);
            optimizelyService.push.creditFormSubmission('questions');
        }
    }
    loadData();
}
exports.IdentityController = IdentityController;
