"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var angular = require("angular");
function showSpinnerDirective() {
    return {
        restrict: 'A',
        link: function (scope, elm, attrs) {
            var ICON_SPINNER_CLASS = 'icon-spinner';
            function showSpinner() {
                return scope.$eval(attrs.showSpinner);
            }
            scope.$watch(showSpinner, function () {
                if (showSpinner()) {
                    elm.append('<i class="' + ICON_SPINNER_CLASS + ' icon-spin"></i>');
                }
                else {
                    angular.forEach(elm.find('i'), function (icon) {
                        icon = angular.element(icon);
                        if (icon.hasClass(ICON_SPINNER_CLASS)) {
                            icon.remove();
                        }
                    });
                }
            });
        },
    };
}
exports.showSpinnerDirective = showSpinnerDirective;
