"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
EligibilityConfig.$inject = ['$routeProvider'];
function EligibilityConfig($routeProvider) {
    var routeEligibility = {};
    routeEligibility.templateUrl = 'views/eligibility/eligibility.html';
    routeEligibility.controller = 'EligibilityController';
    $routeProvider.when('/eligibility', routeEligibility);
}
exports.EligibilityConfig = EligibilityConfig;
