"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
SmbService.$inject = ['smbConfig', 'HttpClient', 'bookingFlowState'];
function SmbService(smbConfig, HttpClient, bookingFlowState) {
    var smbClient = new HttpClient(smbConfig.serviceBaseUrl);
    function getSmartMeterBookingData(smbId) {
        return smbClient.get('/smart-meter-booking/' + smbId);
    }
    function identifyAndCheck(accountNumber, postcode) {
        return smbClient.get('/smart-meter-booking/account-info?accountnumber=' + accountNumber + '&postcode=' + postcode, function (data) {
            return data;
        });
    }
    function initialiseSmartMeterBooking() {
        var data = {
            firstName: bookingFlowState.customerName.get(),
            lastName: '',
            emailAddress: bookingFlowState.emailAddress.get(),
            phoneNumber: bookingFlowState.phoneNumber.get(),
            addressDetails: bookingFlowState.addressDetails.get(),
            eligibilityInfo: bookingFlowState.eligibilityInfo.get(),
            availability: bookingFlowState.availability.get(),
            fuelType: bookingFlowState.fuelType.get(),
            planType: bookingFlowState.planType.get(),
            accountId: bookingFlowState.accountId.get(),
            mpan: bookingFlowState.mpan.get(),
            mprn: bookingFlowState.mprn.get(),
            elecMsn: bookingFlowState.elecMsn.get(),
            gasMsn: bookingFlowState.gasMsn.get(),
            isIGT: false,
            retailerCode: 'OVO',
        };
        return smbClient.post('/smart-meter-booking', data, function (smbId) {
            return smbId;
        });
    }
    function updateEligibilityInfo(smbId, eligibilityInfo) {
        return smbClient.put('/smart-meter-booking/' + smbId + '/eligibility-info', eligibilityInfo);
    }
    function getWanCoverage(smbId) {
        return smbClient.get('/smart-meter-booking/' + smbId + '/eligibility-info/wan-coverage');
    }
    return {
        getSmartMeterBookingData: getSmartMeterBookingData,
        identifyAndCheck: identifyAndCheck,
        initialiseSmartMeterBooking: initialiseSmartMeterBooking,
        updateEligibilityInfo: updateEligibilityInfo,
        getWanCoverage: getWanCoverage,
    };
}
exports.SmbService = SmbService;
