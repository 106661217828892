"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var angular = require("angular");
var booking_flow_service_1 = require("../../services/booking-flow-service/booking-flow-service");
function tiledInstallationSummaryDirective() {
    return {
        restrict: 'E',
        templateUrl: 'components/tiled-installation-summary/tiled-installation-summary.html',
        scope: {
            date: '=',
            timeSlot: '@',
            isCancelScreen: '=',
            showHeading: '=?',
            isProvisional: '=?',
        },
        link: function (scope) {
            if (scope.date) {
                scope.weekDayName = scope.date.format('ddd');
                scope.weekDay = scope.date.format('D MMMM');
            }
            scope.showHeading = angular.isDefined(scope.showHeading) ? scope.showHeading : true;
            scope.$watch('timeSlot', function (newVal) {
                scope.displayedTime = booking_flow_service_1.BookingFlowService.formatTimeOfDay(newVal).replace(/ /g, '');
            });
        },
    };
}
exports.tiledInstallationSummaryDirective = tiledInstallationSummaryDirective;
