"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var moment = require("moment");
var DAYS_PER_WEEK = 7;
var START_OF_WEEK_OFFSET = 1;
var HEADERS = 'MTWTFSS'.split('');
function startOfWeek(date) {
    return date
        .clone()
        .subtract(START_OF_WEEK_OFFSET, 'days')
        .startOf('week')
        .add(START_OF_WEEK_OFFSET, 'days');
}
function numOfWeeksInMonth(month) {
    var weekStart = startOfWeek(month);
    var aheadOneMonth = month
        .clone()
        .startOf('month')
        .add(1, 'month');
    return Math.ceil(aheadOneMonth.diff(weekStart, 'days') / 7);
}
function calculateMatrix(month) {
    var weekStart = startOfWeek(month);
    var weeksInMonth = numOfWeeksInMonth(month);
    return _.range(weeksInMonth).map(function (rowId) {
        return _.range(DAYS_PER_WEEK).map(function (colId) {
            var date = weekStart.clone().add(rowId * DAYS_PER_WEEK + colId, 'days');
            return {
                date: date,
                isDisplayed: date.isSame(month, 'month'),
            };
        });
    });
}
function calendarDirective() {
    return {
        restrict: 'EA',
        replace: true,
        templateUrl: 'components/calendar/calendar.html',
        scope: {
            selectedDate: '=',
            selectableDates: '=',
            initialMonth: '=',
            theme: '=',
        },
        link: function (scope) {
            var thisMonth = moment().startOf('month');
            var hasDoneFirstTime = false;
            var sortedSlots = _.sortBy(scope.selectableDates, function (date) { return date; });
            var lastSelectableDate = moment(sortedSlots[sortedSlots.length - 1]);
            scope.month = scope.initialMonth ? scope.initialMonth.clone().startOf('month') : thisMonth.clone();
            scope.headers = HEADERS;
            scope.$watch('month', function (newVal) {
                scope.rows = calculateMatrix(newVal);
            });
            scope.$watch('selectableDates', function (newVal, oldVal) {
                if (!newVal && !oldVal) {
                    return;
                }
                newVal = newVal || [];
                scope.slots = _.fromPairs(newVal.map(function (date) { return [date.format('YYYY-MM-DD'), true]; }));
                if (!hasDoneFirstTime) {
                    // First time, update the month
                    hasDoneFirstTime = true;
                    var sorted = _.sortBy(newVal, function (date) {
                        return date.valueOf();
                    });
                    if (sorted.length) {
                        scope.month = sorted[0].clone().startOf('month');
                    }
                }
            });
            scope.canGoPrevious = function () { return scope.month.isAfter(thisMonth, 'month'); };
            scope.canGoNext = function () { return !scope.month.isAfter(lastSelectableDate); };
            scope.addMonth = function (num) {
                if ((num < 0 && scope.canGoPrevious()) || (num > 0 && scope.canGoNext())) {
                    var newMonth = scope.month.clone().add(num, 'months');
                    scope.month = newMonth;
                }
            };
            scope.hasSlot = function (date) {
                if (!scope.slots) {
                    return false;
                }
                return !!scope.slots[date.format('YYYY-MM-DD')];
            };
            scope.isSelected = function (date) { return scope.selectedDate.value && scope.selectedDate.value.isSame(date, 'day'); };
            scope.select = function (date) {
                if (scope.hasSlot(date)) {
                    scope.selectedDate.value = date.clone();
                }
            };
        },
    };
}
exports.calendarDirective = calendarDirective;
