"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var types_1 = require("../../types");
SmartMeterDataController.$inject = [
    '$scope',
    'smbNavigate',
    'SmbRoutesService',
    '$location',
    'bookingFlowService',
    'SmbEventReporter',
    '$anchorScroll',
    'SmartMeterTypeService',
];
function SmartMeterDataController($scope, navigate, smbRoutesService, $location, bookingFlowService, smbEventReporter, $anchorScroll, smartMeterTypeService) {
    $scope.bookingFlow = bookingFlowService;
    $scope.smbRoutes = smbRoutesService.getRoutes();
    if ($scope.bookingFlow.appointmentData && ($scope.bookingFlow.isConfirmed || $scope.bookingFlow.isProvisional)) {
        $location.replace();
        $location.url($scope.smbRoutes.confirmationConfirmation);
    }
    if (!$scope.bookingFlow.appointmentData || !$scope.bookingFlow.smbId) {
        $location.replace();
        $location.url($scope.smbRoutes.smartMeterBookingStart);
    }
    $scope.nextPage = function (dataIsSet) {
        if (!dataIsSet) {
            $anchorScroll('readings-frequency');
            return;
        }
        navigate.to($scope.smbRoutes.homeDetails);
    };
    $scope.prevPage = function () { return $scope.smbRoutes.smartMeterBookingStart; };
    $scope.editInstallationDate = function () { return navigate.to($scope.smbRoutes.smartMeterBookingStart); };
    $scope.showExtraFeatures = smartMeterTypeService.getSmartMeterType() !== types_1.SmartTypes.SMETS2;
    smbEventReporter.viewedScreen();
    $anchorScroll();
}
exports.SmartMeterDataController = SmartMeterDataController;
