"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
DeviceFactory.$inject = ['$window', '$timeout'];
function DeviceFactory($window, $timeout) {
    var device = this;
    function onInit() {
        var mobileMediaQuery = '(max-width: 767px)';
        var tabletMediaQuery = '(min-width: 768px) and (max-width: 1298px)';
        var desktopMediaQuery = '(min-width: 1299px)';
        device.isMobile = undefined;
        device.isTablet = undefined;
        device.isDesktop = undefined;
        device.mobileChecker = $window.matchMedia(mobileMediaQuery);
        device.tabletChecker = $window.matchMedia(tabletMediaQuery);
        device.desktopChecker = $window.matchMedia(desktopMediaQuery);
        device.mobileChecker.addListener(screenSizeCheckerOnChange('Mobile'));
        device.tabletChecker.addListener(screenSizeCheckerOnChange('Tablet'));
        device.desktopChecker.addListener(screenSizeCheckerOnChange('Desktop'));
        setInitialValues();
    }
    onInit();
    function setInitialValues() {
        setDeviceType('Mobile', device.mobileChecker.matches);
        setDeviceType('Tablet', device.tabletChecker.matches);
        setDeviceType('Desktop', device.desktopChecker.matches);
    }
    function screenSizeCheckerOnChange(deviceType) {
        return function (_a) {
            var matches = _a.currentTarget.matches;
            setDeviceType(deviceType, matches);
        };
    }
    function setDeviceType(deviceType, value) {
        // this $timeout is needed, otherwise the UI doesn't update after setting the new value
        $timeout(function () { return (device["is" + deviceType] = value); }, 0);
    }
    // public functions
    function isDesktopSize() {
        return device.isDesktop;
    }
    function isTabletSize() {
        return device.isTablet;
    }
    function isMobileSize() {
        return device.isMobile;
    }
    return {
        isDesktopSize: isDesktopSize,
        isTabletSize: isTabletSize,
        isMobileSize: isMobileSize,
    };
}
exports.DeviceFactory = DeviceFactory;
