"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.peopleMethodNames = ['set', 'set_once', 'increment', 'append', 'track_charge', 'delete_user'];
exports.trackingMethodNames = [
    'push',
    'disable',
    'track',
    'track_links',
    'track_forms',
    'register',
    'register_once',
    'unregister',
    'identify',
    'get_distinct_id',
    'alias',
    'set_config',
    'get_config',
    'get_property',
    'unregister',
];
function mixpanelService() {
    var allProjects = [];
    var activeProject;
    function getProjects() {
        return allProjects;
    }
    function addProject(projectName, trackingCode, config) {
        if (window.mixpanel && window.mixpanel.init) {
            window.mixpanel.init(trackingCode, config || {}, projectName);
        }
        allProjects.push({
            name: projectName,
            trackingCode: trackingCode,
        });
    }
    function getActiveProject() {
        return activeProject;
    }
    function setActiveProject(projectName) {
        var searchedProject = allProjects.find(function (project) { return project.name === projectName; });
        activeProject = searchedProject || activeProject;
    }
    function resetActiveProject() {
        activeProject = undefined;
    }
    function methodProxy(method, property) {
        return function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            if (window.mixpanel) {
                var mixpanelScope = window.mixpanel;
                if (activeProject) {
                    mixpanelScope = window.mixpanel[activeProject.name];
                }
                var mixpanelProjectMethod = property && mixpanelScope && mixpanelScope[property]
                    ? mixpanelScope[property][method]
                    : mixpanelScope[method];
                if (mixpanelProjectMethod) {
                    var context = property ? mixpanelScope[property] : mixpanelScope;
                    mixpanelProjectMethod.apply(context, args);
                }
            }
        };
    }
    var peopleProxy = {};
    exports.peopleMethodNames.forEach(function (method) {
        peopleProxy[method] = methodProxy(method, 'people');
    });
    var mixpanelProxy = {
        getProjects: getProjects,
        addProject: addProject,
        setActiveProject: setActiveProject,
        getActiveProject: getActiveProject,
        resetActiveProject: resetActiveProject,
        people: peopleProxy,
    };
    exports.trackingMethodNames.forEach(function (method) {
        mixpanelProxy[method] = methodProxy(method);
    });
    return mixpanelProxy;
}
exports.mixpanelService = mixpanelService;
