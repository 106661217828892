"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SmartTypes;
(function (SmartTypes) {
    SmartTypes["SMETS1"] = "SMETS1";
    SmartTypes["SMETS2"] = "SMETS2";
})(SmartTypes = exports.SmartTypes || (exports.SmartTypes = {}));
var Brands;
(function (Brands) {
    Brands["OVO"] = "OVO";
    Brands["Boost"] = "Boost";
    Brands["Lumo"] = "Lumo";
})(Brands = exports.Brands || (exports.Brands = {}));
var PaymentMethods;
(function (PaymentMethods) {
    PaymentMethods["Credit"] = "Credit";
    PaymentMethods["Payg"] = "Payg";
})(PaymentMethods = exports.PaymentMethods || (exports.PaymentMethods = {}));
