"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
SmartEligibilityPropertyQuestionsController.$inject = [
    '$routeParams',
    '$anchorScroll',
    'smbNavigate',
    'eligibilityService',
    'SmbRoutesService',
    'localStorageService',
    'EventReporter',
];
function SmartEligibilityPropertyQuestionsController($routeParams, $anchorScroll, navigate, eligibilityService, SmbRoutesService, localStorageService, EventReporter) {
    var _this = this;
    this.loading = false;
    this.init = function () {
        eligibilityService.initialise($routeParams);
        if (localStorageService.get('existingAppointment')) {
            if (localStorageService.get('smbId')) {
                navigate.to(SmbRoutesService.getRoutes().smartMeterBookingSetup);
            }
            else {
                navigate.to(SmbRoutesService.getRoutes().appointmentDetails);
            }
        }
        else if (localStorageService.get('hasAbortedAppointment') === 'true' ||
            localStorageService.get('areaEligible') === 'false' ||
            localStorageService.get('meterEligible') === 'false' ||
            localStorageService.get('notOnSupply') === 'true' ||
            localStorageService.get('waitingForGasOnSupply') === 'true' ||
            localStorageService.get('waitingForElecOnSupply') === 'true') {
            navigate.to(SmbRoutesService.getRoutes().eligibility);
        }
        else if (localStorageService.get('canSkipQuestions') === 'true') {
            eligibilityService.setPropertyEligibility(true);
            EventReporter.genericEvent('submit-question', {
                Skipped: true,
            });
            navigate.to(SmbRoutesService.getRoutes().smartMeterBookingSetup);
            return;
        }
        this.changeQuestion(eligibilityService.getFirstPropertyQuestion());
    };
    this.questionDisplay = {
        sameFloor: {
            title: 'Are your electricity and gas meters on the same floor?',
            iconClass: 'same-floor',
        },
        flat: {
            title: 'Do you live in a flat?',
            iconClass: 'flat',
        },
        storageHeaters: {
            title: 'Do you use storage heaters?',
            iconClass: 'storage-heaters',
        },
    };
    this.chooseAnswer = function (answer) {
        if (_this.loading) {
            return;
        }
        var boolAnswer = toBoolean(answer);
        switch (_this.currentQuestionId) {
            case 'flat':
                eligibilityService.setInFlat(boolAnswer);
                break;
            case 'storageHeaters':
                eligibilityService.setStorageHeaters(boolAnswer);
                break;
            case 'sameFloor':
                eligibilityService.setMetersDiffFloor(!boolAnswer);
                break;
            default:
                throw new Error('Unknown question name: ' + _this.currentQuestionId);
        }
        var questionResult = eligibilityService.getPropertyQuestionResult(_this.currentQuestionId, answer);
        var genericEventProperties = {
            Question: _this.questionDisplay[_this.currentQuestionId].title,
            Skipped: false,
        };
        if (questionResult === 'eligible') {
            eligibilityService.setPropertyEligibility(true);
            EventReporter.genericEvent('submit-question', __assign(__assign({}, genericEventProperties), { Answer: answer, Outcome: 'Eligible' }));
            navigate.to(SmbRoutesService.getRoutes().eligibility);
        }
        else if (questionResult === 'notEligible') {
            eligibilityService.setPropertyEligibility(false);
            EventReporter.genericEvent('submit-question', __assign(__assign({}, genericEventProperties), { Answer: answer, Outcome: 'Not Eligible' }));
            navigate.to(SmbRoutesService.getRoutes().eligibility);
        }
        else {
            EventReporter.genericEvent('submit-question', __assign(__assign({}, genericEventProperties), { Answer: answer, Outcome: 'Next Question' }));
            _this.changeQuestion(questionResult);
        }
    };
    this.changeQuestion = function (questionId) {
        _this.currentQuestionId = questionId;
        EventReporter.viewedScreen({
            Question: _this.questionDisplay[_this.currentQuestionId].title,
        }, 'question');
        $anchorScroll('property-questions');
    };
    function toBoolean(answer) {
        switch (answer) {
            case 'yes':
                return true;
            case 'no':
                return false;
            case 'unknown':
                return undefined;
            default:
                throw new Error("Unknown answer type : " + answer);
        }
    }
    this.init();
}
exports.SmartEligibilityPropertyQuestionsController = SmartEligibilityPropertyQuestionsController;
