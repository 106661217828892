"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var angular = require("angular");
HttpClientFactory.$inject = ['$q', '$http', 'TraceTokenService'];
function HttpClientFactory($q, $http, TraceTokenService) {
    function HttpClient(baseUrl, clientOptions) {
        var self = this;
        this.pendingRequests = {};
        this.createRequest = function (method, path, transform, options) {
            if (transform && angular.isObject(transform)) {
                options = transform;
                transform = undefined;
            }
            var traceToken = TraceTokenService.getCurrentTraceToken();
            return {
                method: method,
                url: baseUrl + path,
                options: angular.extend({ headers: { 'X-Trace-Token': traceToken } }, angular.copy(clientOptions || {}), options || {}),
                deferred: $q.defer(),
                transform: transform,
            };
        };
        this.handleResponse = function (resp, request) {
            return resp.then(function (response) {
                if (request.method === 'get') {
                    delete self.pendingRequests[request.url];
                }
                if (request.transform) {
                    response.data = request.transform(response.data, response);
                }
                request.deferred.resolve(request.options.fullResponse ? response : response.data);
            }, function (error) {
                if (request.method === 'get') {
                    delete self.pendingRequests[request.url];
                }
                error.data = error.data || {};
                var errorObject = new Error(error.data.message || 'Internal server error');
                errorObject.code = error.data.code || 0;
                errorObject.response = error;
                request.deferred.reject(errorObject);
            });
        };
        this.get = function (path, transform, requestOptions) {
            var request = self.createRequest('get', path, transform, requestOptions);
            if (self.pendingRequests[request.url]) {
                return self.pendingRequests[request.url].promise;
            }
            self.pendingRequests[request.url] = request.deferred;
            self.handleResponse($http.get(request.url, request.options), request);
            return request.deferred.promise;
        };
        this.delete = function (path, transform, requestOptions) {
            var request = self.createRequest('delete', path, transform, requestOptions);
            self.handleResponse($http.delete(request.url, request.options), request);
            return request.deferred.promise;
        };
        this.post = function (path, data, transform, requestOptions) {
            var request = self.createRequest('post', path, transform, requestOptions);
            self.handleResponse($http.post(request.url, data, request.options), request);
            return request.deferred.promise;
        };
        this.put = function (path, data, transform, requestOptions) {
            var request = self.createRequest('put', path, transform, requestOptions);
            self.handleResponse($http.put(request.url, data, request.options), request);
            return request.deferred.promise;
        };
        this.all = function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            var promises = args;
            return {
                then: function (successCallback, errorCallback) {
                    $q.all(promises).then(function (results) {
                        var resultsArray = [];
                        angular.forEach(results, function (result, index) {
                            resultsArray[index] = result;
                        });
                        successCallback.apply(successCallback, resultsArray);
                    }, errorCallback);
                },
            };
        };
    }
    return HttpClient;
}
exports.HttpClientFactory = HttpClientFactory;
