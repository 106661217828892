"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var angular = require("angular");
var app_config_1 = require("./app-config/app-config");
var bolton_service_1 = require("./bolton/bolton.service");
var booking_flow_service_1 = require("./booking-flow-service/booking-flow-service");
var core_provider_1 = require("./core-provider/core-provider");
var eligibility_service_1 = require("./eligibility-service/eligibility-service");
var smb_event_reporter_1 = require("./smb-event-reporter/smb-event-reporter");
var feature_flag_service_1 = require("./feature-flag/feature-flag.service");
var form_content_service_1 = require("./form-content-service/form-content-service");
var optimizely_service_1 = require("./optimizely-service/optimizely-service");
var smart_meter_booking_mixpanel_1 = require("./smart-meter-booking-mixpanel/smart-meter-booking-mixpanel");
var smart_meter_booking_service_1 = require("./smart-meter-booking-service/smart-meter-booking-service");
var smart_meter_type_service_1 = require("./smart-meter-type-service/smart-meter-type-service");
var smb_app_mixpanel_1 = require("./smb-app-mixpanel/smb-app-mixpanel");
var smb_config_provider_1 = require("./smb-config-provider/smb-config-provider");
var event_reporter_1 = require("./event-reporter/event-reporter");
var smb_routes_service_1 = require("./smb-routes-service/smb-routes-service");
var smb_service_1 = require("./smb-service/smb-service");
var trace_token_service_1 = require("./trace-token-service/trace-token-service");
var wan_coverage_service_1 = require("./wan-coverage-service/wan-coverage-service");
var utm_param_provider_1 = require("./utm-param-provider/utm-param-provider");
// TODO: It'd better to have all factories or services if possible
angular
    .module('ovo.smb.services', [])
    .factory('AppConfig', app_config_1.ApplicationConfig)
    .service('boltonService', bolton_service_1.BoltonService)
    .service('bookingFlowService', booking_flow_service_1.BookingFlowService)
    .provider('$core', core_provider_1.CoreProvider)
    .factory('eligibilityService', eligibility_service_1.eligibilityService)
    .service('SmbEventReporter', smb_event_reporter_1.SmbEventReporter)
    .service('featureFlag', feature_flag_service_1.FeatureFlag)
    .service('formContentService', form_content_service_1.FormContentService)
    .service('optimizelyService', optimizely_service_1.default)
    .factory('smbMixpanel', smart_meter_booking_mixpanel_1.SmbMixpanelFactory)
    .service('SmartMeterBookingService', smart_meter_booking_service_1.SmartMeterBookingService)
    .service('SmartMeterTypeService', smart_meter_type_service_1.SmartMeterTypeService)
    .factory('smbAppMixpanel', smb_app_mixpanel_1.SmbAppMixpanelFactory)
    .provider('smbConfig', smb_config_provider_1.SmbConfigProvider)
    .factory('EventReporter', event_reporter_1.EventReporterFactory)
    .service('SmbRoutesService', smb_routes_service_1.SmbRoutesService)
    .factory('SmbService', smb_service_1.SmbService)
    .service('TraceTokenService', trace_token_service_1.TraceTokenService)
    .factory('WanCoverageService', wan_coverage_service_1.WanCoverageService)
    .provider('utmParam', utm_param_provider_1.UtmParamProvider);
