"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
HomeDetailsConfig.$inject = ['$routeProvider'];
function HomeDetailsConfig($routeProvider) {
    var routeHomeDetails = {};
    routeHomeDetails.templateUrl = 'views/home-details/home-details.html';
    routeHomeDetails.controller = 'HomeDetailsController';
    $routeProvider.when('/smart-meter-booking/home-details', routeHomeDetails);
}
exports.HomeDetailsConfig = HomeDetailsConfig;
