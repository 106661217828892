"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function textInputDirective() {
    return {
        restrict: 'EA',
        replace: true,
        templateUrl: 'components/text-input/text-input.html',
        scope: {
            model: '=ngModel',
            placeholder: '=',
            blur: '&ngBlur',
            focus: '&ngFocus',
            error: '=error',
        },
        link: function (scope) {
            scope.hasFocus = false;
        },
    };
}
exports.textInputDirective = textInputDirective;
