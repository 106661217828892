"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var types_1 = require("../../types");
smetsBookingInfoDirective.$inject = [
    'localStorageService',
    'smbNavigate',
    'SmbRoutesService',
    'eligibilityService',
    'SmartMeterTypeService',
];
function smetsBookingInfoDirective(localStorageService, navigate, SmbRoutesService, eligibilityService, smartMeterTypeService) {
    return {
        restrict: 'E',
        templateUrl: 'components/smets-booking-info/smets-booking-info.html',
        link: function (scope) {
            var smartMeterType = smartMeterTypeService.getSmartMeterType();
            var smets2Availability = eligibilityService.getAvailability(types_1.SmartTypes.SMETS2);
            var wanCoverage = localStorageService.get('wanCoverage');
            scope.message = getMessage();
            scope.bookSMETS1 = function () {
                smartMeterTypeService.setSmartMeterType(types_1.SmartTypes.SMETS1);
                gotoSmartMeterAvailability();
            };
            scope.bookSMETS2 = function () {
                smartMeterTypeService.setSmartMeterType(types_1.SmartTypes.SMETS2);
                gotoSmartMeterAvailability();
            };
            function getMessage() {
                if (smartMeterType === types_1.SmartTypes.SMETS2) {
                    return 'smets2';
                }
                if (smartMeterType === types_1.SmartTypes.SMETS1 &&
                    eligibilityService.isSMETS2Eligible() &&
                    wanCoverage === 'HasCoverage' &&
                    smets2Availability !== 'None') {
                    return 'smets2eligible';
                }
                if (smartMeterType === types_1.SmartTypes.SMETS1 && wanCoverage === 'HasCoverage') {
                    return 'smets2notavailable';
                }
                return null;
            }
            function gotoSmartMeterAvailability() {
                navigate.to(SmbRoutesService.getRoutes().availability);
            }
        },
    };
}
exports.smetsBookingInfoDirective = smetsBookingInfoDirective;
