"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
SmartEligibilityPropertyQuestionsConfig.$inject = ['$routeProvider'];
function SmartEligibilityPropertyQuestionsConfig($routeProvider) {
    var routeSmartEligibilityPropertyQuestions = {};
    routeSmartEligibilityPropertyQuestions.templateUrl = 'views/property-questions/property-questions.html';
    routeSmartEligibilityPropertyQuestions.controller = 'SmartEligibilityPropertyQuestionsController';
    routeSmartEligibilityPropertyQuestions.controllerAs = 'ctrl';
    $routeProvider.when('/smart-eligibility/property-questions/:fuelType', routeSmartEligibilityPropertyQuestions);
}
exports.SmartEligibilityPropertyQuestionsConfig = SmartEligibilityPropertyQuestionsConfig;
