"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var InfoTooltipComponentBindings;
(function (InfoTooltipComponentBindings) {
    InfoTooltipComponentBindings["CONTENT_TEMPLATE"] = "contentTemplate";
    InfoTooltipComponentBindings["SCREEN_READER_MESSAGE"] = "screenReaderMessage";
    InfoTooltipComponentBindings["IS_FOCUSABLE"] = "isFocusable";
})(InfoTooltipComponentBindings || (InfoTooltipComponentBindings = {}));
exports.infoTooltipComponentConfig = {
    templateUrl: 'components/common/info-tooltip/info-tooltip.html',
    bindings: {
        contentTemplate: '@',
        screenReaderMessage: '@',
        isFocusable: '<',
    },
    controller: InfoTooltipController,
};
InfoTooltipController.$inject = ['Device'];
function InfoTooltipController(Device) {
    var tooltip = this;
    tooltip.$onInit = onInit;
    function onInit() {
        var mandatoryProperties = [
            InfoTooltipComponentBindings.CONTENT_TEMPLATE,
            InfoTooltipComponentBindings.SCREEN_READER_MESSAGE,
        ];
        checkMandatoryProperties(mandatoryProperties);
        setPropertiesDefaultValues();
        tooltip.isMobile = Device.isMobileSize;
        tooltip.tabIndex = tooltip.isFocusable ? '0' : '-1';
    }
    function checkMandatoryProperties(props) {
        var errorProps = props.filter(function (propertyName) { return tooltip[propertyName] === undefined; });
        if (errorProps.length) {
            var propsList = errorProps.join(', ');
            var isPlural = errorProps.length > 1;
            // eslint-disable-next-line no-console
            console.error("InfoTooltip component: " + propsList + " " + (isPlural ? 'properties' : 'property') + " " + (isPlural ? 'are' : 'is') + " required");
        }
    }
    function setPropertiesDefaultValues() {
        tooltip.isFocusable = tooltip.isFocusable !== undefined ? tooltip.isFocusable : true;
    }
}
