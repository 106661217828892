"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var BoltonService = /** @class */ (function () {
    function BoltonService($cookies, $http, $q, AppConfig) {
        this.$cookies = $cookies;
        this.$http = $http;
        this.$q = $q;
        this.AppConfig = AppConfig;
    }
    BoltonService.prototype.isOvoBeyondEligible = function (globalAccountId) {
        if (!this.isLoggedIn()) {
            return this.$q.resolve(false);
        }
        return this.$http
            .get(this.AppConfig.boltonsApiBaseUrl + "account/" + globalAccountId + "/catalogue")
            .then(function (_a) {
            var data = _a.data;
            return data;
        })
            .then(function (_a) {
            var boltons = _a.boltons;
            return boltons.some(function (_a) {
                var productId = _a.productId, eligibility = _a.eligibility;
                return productId === BoltonService.beyondProductId && eligibility.eligible;
            });
        });
    };
    BoltonService.prototype.isLoggedIn = function () {
        // This cookie is set when the user logs in correctly.
        // Checking for its presence should tell us precisely
        // enough if a user is logged in or not
        return !!this.$cookies.get('token');
    };
    BoltonService.$inject = ['$cookies', '$http', '$q', 'AppConfig'];
    BoltonService.beyondProductId = '8f09c61e-60e0-4779-87de-77d38ff46d7d';
    return BoltonService;
}());
exports.BoltonService = BoltonService;
