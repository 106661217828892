"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OVO_BEYOND = 'OVO_BEYOND';
exports.NEW_LOGIN_PAGE = 'NEW_LOGIN_PAGE';
exports.USE_5TH_TERMINAL_FLOW = 'USE_5TH_TERMINAL_FLOW';
var FeatureFlag = /** @class */ (function () {
    function FeatureFlag(AppConfig) {
        var _a;
        this.AppConfig = AppConfig;
        this.featureList = (_a = {},
            _a[exports.OVO_BEYOND] = true,
            _a[exports.NEW_LOGIN_PAGE] = true,
            _a[exports.USE_5TH_TERMINAL_FLOW] = this.AppConfig.useFifthTerminalFlow,
            _a);
    }
    FeatureFlag.prototype.isFeatureActive = function (feature) {
        return this.featureList[feature] || false;
    };
    FeatureFlag.$inject = ['AppConfig'];
    return FeatureFlag;
}());
exports.FeatureFlag = FeatureFlag;
