"use strict";
// this service creates a modal from scratch
Object.defineProperty(exports, "__esModule", { value: true });
// when calling open()
//      a backdrop( dark background ) is injected into DOM
//      a scope data context and a chosen template is compiled ( angular compiler ) and injected into DOM
// event 'hidden.bs.modal' is triggered by bootstrap, we perform cleanup = remove backdrop and modal from DOM
// areas for improvement, getTemplateByType()  make sure returned template can be loaded from ajax, makes for better dev experience to edit an html file instead
var $ = require("jquery");
var SmbModalService = /** @class */ (function () {
    function SmbModalService($compile, $rootScope) {
        var _this = this;
        this.$compile = $compile;
        this.$rootScope = $rootScope;
        this.types = {
            generic: {
                cssClass: '',
                templateUrl: 'views/modals/modal-containers/generic.html',
            },
            warning: {
                cssClass: '',
                templateUrl: 'views/modals/modal-containers/warning.html',
            },
        };
        this.id = '#modal';
        $(document).on('hidden.bs.modal', function () {
            _this.destroy();
        });
    }
    SmbModalService.prototype.close = function () {
        $(this.id).modal('hide');
    };
    SmbModalService.prototype.destroy = function () {
        $(this.id).remove();
        $('#backdrop').remove();
        this.$rootScope.modalVisible = false;
    };
    SmbModalService.prototype.open = function (scope, type, backdropConfig) {
        var _this = this;
        this.type = type;
        this.createModal(scope, function () {
            $(_this.id).modal(_this.getModalOptionsWithEscapeDisabled());
        });
        this.createBackdrop(backdropConfig, scope);
        this.$rootScope.modalVisible = true;
    };
    SmbModalService.prototype.getModalOptionsWithEscapeDisabled = function () {
        return {
            backdrop: true,
            keyboard: true,
        };
    };
    SmbModalService.prototype.createBackdrop = function (backdropConfig, scope) {
        var opacity = 0.5, background = 'black', headerHtml = '', backdropClass = '';
        if (backdropConfig) {
            opacity = backdropConfig.opacity;
            background = backdropConfig.backgroundColor;
            headerHtml = backdropConfig.headerHtml;
            backdropClass = backdropConfig.backdropClass;
        }
        var ref = this.$compile("\n            <div id=\"backdrop\" \n                    class=\"" + backdropClass + "\"\n                    style=\"   \n                        position: fixed;\n                        bottom: 0;\n                        display: none;\n                        top: 0;\n                        left: 0;\n                        right: 0;\n                        opacity: " + opacity + ";\n                        z-index: 1040;\n                        background: " + background + ";\n                        \">\n                   " + headerHtml + "     \n            </div>")(scope);
        $(this.id).before(ref);
        setTimeout(function () {
            scope.$apply();
        });
        $('#backdrop').show();
    };
    SmbModalService.prototype.getTemplate = function (cssClass, templateUrl) {
        return "<div class=\"modal fade " + cssClass + "\" id=\"modal\" role=\"dialog\" >\n                    <div ng-include=\"'" + templateUrl + "'\" ></div> \n                </div>";
    };
    SmbModalService.prototype.getTemplateByType = function () {
        if (!this.types[this.type]) {
            return '';
        }
        var modal = this.types[this.type];
        return this.getTemplate(modal.cssClass, modal.templateUrl);
    };
    SmbModalService.prototype.createModal = function (scope, cb) {
        scope.close = this.close.bind(this);
        var ref = this.$compile(this.getTemplateByType())(scope);
        $('body').append(ref);
        setTimeout(function () {
            scope.$apply();
            cb();
        });
    };
    SmbModalService.$inject = ['$compile', '$rootScope'];
    SmbModalService.headers = Array;
    return SmbModalService;
}());
exports.SmbModalService = SmbModalService;
