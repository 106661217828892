"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var angular = require("angular");
var beyond_banner_1 = require("./beyond-banner/beyond-banner");
var card_component_1 = require("./common/card/card.component");
var info_tooltip_component_1 = require("./common/info-tooltip/info-tooltip.component");
var page_header_component_1 = require("./common/page-header/page-header.component");
angular
    .module('ovo.smb.components', [])
    .component('beyondBanner', beyond_banner_1.beyondBanner)
    .component('beyondBannerFootnotes', beyond_banner_1.beyondBannerFootnotes)
    .component('card', card_component_1.cardComponentConfig)
    .component('infoTooltip', info_tooltip_component_1.infoTooltipComponentConfig)
    .component('pageHeader', page_header_component_1.pageHeaderComponentConfig);
