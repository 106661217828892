"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function VersionCompare() {
    function _map(array, callback) {
        var newArray = [];
        for (var i = 0; i < array.length; i++) {
            newArray[i] = callback(array[i]);
        }
        return newArray;
    }
    function _every(array, callback) {
        // TODO: There must be a better way to do this
        for (var _i = 0, array_1 = array; _i < array_1.length; _i++) {
            var elem = array_1[_i];
            if (callback(elem) === false) {
                return false;
            }
        }
        return true;
    }
    // See https://gist.github.com/TheDistantSea/8021359 for documentation
    function compare(v1, v2, options) {
        var lexicographical = options && options.lexicographical;
        var zeroExtend = options && options.zeroExtend;
        var v1parts = v1.split('.'), v2parts = v2.split('.');
        function isValidPart(x) {
            return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
        }
        if (!_every(v1parts, isValidPart) || !_every(v2parts, isValidPart)) {
            return NaN;
        }
        if (zeroExtend) {
            while (v1parts.length < v2parts.length) {
                v1parts.push('0');
            }
            while (v2parts.length < v1parts.length) {
                v2parts.push('0');
            }
        }
        if (!lexicographical) {
            v1parts = _map(v1parts, Number);
            v2parts = _map(v2parts, Number);
        }
        for (var i = 0; i < v1parts.length; ++i) {
            if (v2parts.length === i) {
                return 1;
            }
            if (v1parts[i] > v2parts[i]) {
                return 1;
            }
            else if (v1parts[i] < v2parts[i]) {
                return -1;
            }
        }
        if (v1parts.length !== v2parts.length) {
            return -1;
        }
        return 0;
    }
    return {
        compare: compare,
    };
}
exports.VersionCompare = VersionCompare;
