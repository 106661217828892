"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var angular = require("angular");
matchHeightDirective.$inject = ['$timeout'];
function matchHeightDirective($timeout) {
    function resizeColumns(element, classToMatch, minHeight) {
        var elementToMatch = angular.element(document.querySelector('.' + classToMatch))[0];
        element.style.minHeight = Math.max(minHeight, elementToMatch.offsetHeight) + 'px';
    }
    return {
        restrict: 'A',
        link: function (_scope, element, attrs) {
            var classToMatch = attrs.matchHeight;
            var minMatchHeight = attrs.matchHeightMinPx;
            $timeout(function () { return resizeColumns(element[0], classToMatch, minMatchHeight); });
            window.onresize = function () {
                resizeColumns(element[0], classToMatch, minMatchHeight);
            };
        },
    };
}
exports.matchHeightDirective = matchHeightDirective;
