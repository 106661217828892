"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SmartMeterTypeService = /** @class */ (function () {
    function SmartMeterTypeService(localStorageService) {
        this._localStorageService = localStorageService;
    }
    SmartMeterTypeService.prototype.getSmartMeterType = function () {
        return this._localStorageService.get('smartMeterType');
    };
    SmartMeterTypeService.prototype.setSmartMeterType = function (smartMeterType) {
        this._localStorageService.set('smartMeterType', smartMeterType);
    };
    SmartMeterTypeService.$inject = ['localStorageService'];
    return SmartMeterTypeService;
}());
exports.SmartMeterTypeService = SmartMeterTypeService;
