"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CoreProvider = /** @class */ (function () {
    function CoreProvider() {
        this.coreConfig = {
            homePage: '/',
            accountPage: undefined,
            isCustomerPortal: true,
            logoSvg: '/img/svg/logo.svg',
            logoStickySvg: '/img/svg/logo-sticky.svg',
            cookieDomain: undefined,
        };
    }
    CoreProvider.prototype.setHomePage = function (path) {
        this.coreConfig.homePage = path;
    };
    CoreProvider.prototype.setAccountPage = function (path) {
        this.coreConfig.accountPage = path;
    };
    CoreProvider.prototype.isCustomerPortal = function (value) {
        this.coreConfig.isCustomerPortal = value;
    };
    CoreProvider.prototype.setLogoSvg = function (path) {
        this.coreConfig.logoSvg = path;
    };
    CoreProvider.prototype.setLogoStickySvg = function (path) {
        this.coreConfig.logoStickySvg = path;
    };
    CoreProvider.prototype.setCookieDomain = function (domain) {
        this.coreConfig.cookieDomain = domain;
    };
    CoreProvider.prototype.$get = function () {
        return this.coreConfig;
    };
    return CoreProvider;
}());
exports.CoreProvider = CoreProvider;
