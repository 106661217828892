"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cardComponentConfig = {
    templateUrl: 'components/common/card/card.html',
    bindings: {
        title: '@?',
        titleSize: '@?',
    },
    transclude: true,
};
