"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function showLoaderDirective() {
    return {
        restrict: 'A',
        replace: true,
        transclude: true,
        scope: {
            loading: '=showLoader',
        },
        templateUrl: 'components/loader/loader.html',
    };
}
exports.showLoaderDirective = showLoaderDirective;
