"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function ovoField() {
    return {
        restrict: 'EA',
        replace: true,
        transclude: true,
        require: '^form',
        scope: true,
        templateUrl: 'components/forms/field.html',
        link: function (scope, _elm, attrs, form) {
            scope.form = form;
            scope.field = form[attrs.name];
            scope.icon = attrs.icon || undefined;
            scope.messages = {};
            scope.messages.required = attrs.requiredMessage || 'The field is required';
            scope.messages.email = attrs.emailMessage || 'The email is not valid';
            scope.messages.number = attrs.numberMessage || 'The number is not valid';
            scope.messages.min = attrs.minMessage || 'The number is not valid';
            scope.messages.max = attrs.maxMessage || 'The number is not valid';
            scope.messages.minlength = attrs.minlengthMessage || 'The value is too short';
            scope.messages.maxlength = attrs.maxlengthMessage || 'The value is too long';
            scope.messages.pattern = attrs.patternMessage || 'The value has an invalid format';
            scope.messages.parse = attrs.parseMessage || 'The value has an invalid format';
            var fieldScopeProperty = "form['" + attrs.name + "'].$viewValue";
            scope.$watch(fieldScopeProperty, function () {
                if (scope.field) {
                    scope.field.$customError = undefined;
                }
            });
        },
    };
}
exports.ovoField = ovoField;
