"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var moment = require("moment");
AppointmentDetailsController.$inject = ['$scope', 'localStorageService', 'EventReporter'];
function AppointmentDetailsController($scope, localStorageService, EventReporter) {
    EventReporter.viewedScreen();
    $scope.existingAppointment = localStorageService.get('existingAppointment');
    $scope.appointmentReference = $scope.existingAppointment.appointmentReference;
    $scope.customerReference =
        $scope.existingAppointment.newCustomerReference || $scope.existingAppointment.customerReference;
    $scope.startDate = moment.utc($scope.existingAppointment.appointmentDate);
    $scope.endDate = moment.utc($scope.existingAppointment.appointmentEndDate);
}
exports.AppointmentDetailsController = AppointmentDetailsController;
