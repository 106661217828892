"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
EventReporterFactory.$inject = ['$route'];
function EventReporterFactory($route) {
    var listeners = [];
    var constants = {
        ViewedScreen: 'viewed-screen',
        SystemError: 'system-error',
        GenericEvent: 'generic-event',
    };
    return {
        raise: raise,
        addListener: addListener,
        constants: constants,
        viewedScreen: viewedScreen,
        systemError: systemError,
        genericEvent: genericEvent,
    };
    function raise(eventType, eventName, properties) {
        listeners.forEach(function (listener) {
            listener(eventType, eventName, properties);
        });
    }
    function viewedScreen(properties, screenName) {
        properties = properties || {};
        screenName = screenName || $route.current.$$route.originalPath.replace(/^\//, '');
        screenName = screenName || 'identity';
        raise(constants.ViewedScreen, screenName, properties);
    }
    function systemError(errorType, error, properties) {
        properties = properties ? properties : {};
        properties = _.extend(properties, { Error: error });
        raise(constants.SystemError, errorType, properties);
    }
    function genericEvent(eventName, properties) {
        properties = properties ? properties : {};
        raise(constants.GenericEvent, eventName, properties);
    }
    function addListener(listener) {
        listeners.push(listener);
    }
}
exports.EventReporterFactory = EventReporterFactory;
