"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var angular = require("angular");
var confirmation_1 = require("./confirmation");
var confirmation_confirmation_1 = require("./confirmation-confirmation");
var priority_services_link_1 = require("./priority-services-link/priority-services-link");
var confirmation_routing_1 = require("./confirmation-routing");
angular
    .module('ovo.smb.confirmation', ['ngRoute'])
    .config(confirmation_routing_1.ConfirmationConfig)
    .controller('SmartMeterConfirmationConfirmationController', confirmation_confirmation_1.SmartMeterConfirmationConfirmationController)
    .component('priorityServicesLink', priority_services_link_1.priorityServicesLink)
    .controller('SmartMeterConfirmationController', confirmation_1.SmartMeterConfirmationController);
