"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var optimizely_service_1 = require("optimizely-service");
var smb_routes_service_1 = require("../smb-routes-service/smb-routes-service");
var OptimizelyService = /** @class */ (function () {
    function OptimizelyService($rootScope, $location) {
        this.$rootScope = $rootScope;
        this.$location = $location;
        this.activeVariationIs = optimizely_service_1.default.activeVariationIs;
        this.activatePage = optimizely_service_1.default.activatePage;
        this.push = {
            customEvent: function (eventName, tags) { return optimizely_service_1.default.pushEvent(eventName, tags); },
            creditFormSubmission: function (status) {
                return optimizely_service_1.default.pushEvent('SMBCreditFormSubmission', { status: status });
            },
        };
    }
    // If and when we need to add audience properties we will need to activate
    // pages manually to make sure all the data is there
    OptimizelyService.prototype.triggerPageChanges = function () {
        var _this = this;
        this.$rootScope.$on('$locationChangeSuccess', function () {
            var locationPath = _this.$location.path();
            var routeKey = Object.keys(smb_routes_service_1.routes).find(function (key) { return smb_routes_service_1.routes[key] === locationPath; });
            var title = smb_routes_service_1.titles[routeKey];
            _this.activatePage(title);
        });
    };
    OptimizelyService.$inject = ['$rootScope', '$location'];
    return OptimizelyService;
}());
exports.default = OptimizelyService;
