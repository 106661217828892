"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SmbConfigProvider = /** @class */ (function () {
    function SmbConfigProvider() {
        this.SmbConfig = {
            serviceBaseUrl: '',
        };
    }
    SmbConfigProvider.prototype.setServiceBaseUrl = function (serviceBaseUrl) {
        this.SmbConfig.serviceBaseUrl = serviceBaseUrl;
    };
    SmbConfigProvider.prototype.$get = function () {
        return this.SmbConfig;
    };
    return SmbConfigProvider;
}());
exports.SmbConfigProvider = SmbConfigProvider;
