"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var booking_flow_service_1 = require("../../services/booking-flow-service/booking-flow-service");
function centredInstallationSummaryDirective() {
    return {
        restrict: 'E',
        templateUrl: 'components/centred-installation-summary/centred-installation-summary.html',
        scope: {
            date: '@',
            timeSlot: '@',
            isCancelScreen: '=',
        },
        link: function (scope) {
            scope.$watch('timeSlot', function (newVal) {
                scope.displayedTime = booking_flow_service_1.BookingFlowService.formatTimeOfDay(newVal);
            });
        },
    };
}
exports.centredInstallationSummaryDirective = centredInstallationSummaryDirective;
