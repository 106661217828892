"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var angular = require("angular");
var banner_1 = require("./banner/banner");
var terms_1 = require("./banner/terms");
var calendar_1 = require("./calendar/calendar");
var calendar_mini_continuous_1 = require("./calendar-mini-continuous/calendar-mini-continuous");
var centred_installation_summary_1 = require("./centred-installation-summary/centred-installation-summary");
var error_1 = require("./error/error");
var field_1 = require("./forms/field");
var form_1 = require("./forms/form");
var loader_1 = require("./loader/loader");
var smb_flow_header_1 = require("./smb-flow-header/smb-flow-header");
var smets_booking_info_1 = require("./smets-booking-info/smets-booking-info");
var spinner_1 = require("./spinner/spinner");
var text_input_1 = require("./text-input/text-input");
var tiled_installation_summary_1 = require("./tiled-installation-summary/tiled-installation-summary");
// TODO: Where possible we should try and migrate from directives
// to components. So this file should be made unnnecessary or
// containing simply a couple of directives
angular
    .module('ovo.smb.directives', [])
    .directive('banner', banner_1.bannerDirective)
    .directive('bannerTerms', terms_1.bannerTermsDirective)
    .directive('calendar', calendar_1.calendarDirective)
    .directive('calendarMiniContinuous', calendar_mini_continuous_1.calendarMiniContinuousDirective)
    .directive('centredInstallationSummary', centred_installation_summary_1.centredInstallationSummaryDirective)
    .directive('error', error_1.errorDirective)
    .directive('ovoField', field_1.ovoField)
    .directive('ovoForm', form_1.ovoForm)
    .directive('showLoader', loader_1.showLoaderDirective)
    .directive('smbFlowHeader', smb_flow_header_1.smbFlowHeaderDirective)
    .directive('smetsBookingInfo', smets_booking_info_1.smetsBookingInfoDirective)
    .directive('showSpinner', spinner_1.showSpinnerDirective)
    .directive('textInput', text_input_1.textInputDirective)
    .directive('tiledInstallationSummary', tiled_installation_summary_1.tiledInstallationSummaryDirective);
