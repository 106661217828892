"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FormContentService = /** @class */ (function () {
    function FormContentService() {
        this.vulnerabilityCodes = [
            {
                id: 'Aged60+',
                description: 'Aged 60+',
            },
            {
                id: 'Blind',
                description: 'Blind',
            },
            {
                id: 'BrailleUser',
                description: 'Braille User',
            },
            {
                id: 'PoorSight',
                description: 'Poor Sight',
            },
            {
                id: 'Deaf',
                description: 'Deaf',
            },
            {
                id: 'PoorHearing',
                description: 'Poor Hearing',
            },
            {
                id: 'PoorSpeech',
                description: 'Poor Speech',
            },
            {
                id: 'PoorSenseOfSmell',
                description: 'Poor Sense of Smell',
            },
            {
                id: 'ArthriticHands',
                description: 'Arthritic Hands',
            },
            {
                id: 'ArthriticAll',
                description: 'Arthritic All',
            },
            {
                id: 'PoorWalking',
                description: 'Poor Walking',
            },
            {
                id: 'Wheelchair',
                description: 'Wheelchair',
            },
            {
                id: 'Bedridden',
                description: 'Bedridden',
            },
            {
                id: 'MentalHandicap',
                description: 'Learning Disability',
            },
            {
                id: 'Dementia',
                description: 'Dementia',
            },
            {
                id: 'SeriousIllness',
                description: 'Serious Illness',
            },
            {
                id: 'HeartCondition',
                description: 'Heart Condition',
            },
            {
                id: 'BreathingDifficulty',
                description: 'Breathing Difficulty',
            },
            {
                id: 'Other',
                description: 'Other Disability',
            },
        ];
    }
    return FormContentService;
}());
exports.FormContentService = FormContentService;
