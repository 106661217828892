"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var angular = require("angular");
ovoForm.$inject = ['$q'];
function ovoForm($q) {
    return {
        restrict: 'EA',
        replace: true,
        transclude: true,
        scope: {
            onSubmit: '&onSubmit',
            onValidationError: '&onValidationError',
            ngModel: '=',
            validateOnChange: '@',
            formId: '@',
        },
        templateUrl: 'components/forms/form.html',
        link: function (scope, _elm, attrs) {
            var form = scope.$eval('form');
            if (attrs.ngModel) {
                scope.ngModel = form;
            }
            if (scope.validateOnChange === 'true') {
                form.$validateOnChange = true;
            }
            form.setErrors = function (errorData) {
                for (var fieldName in errorData) {
                    if (form[fieldName]) {
                        form[fieldName].$customError = errorData[fieldName];
                    }
                }
            };
            function getFormFields() {
                var fields = [];
                for (var key in form) {
                    if (key.indexOf('$') !== 0 && angular.isFunction(form[key])) {
                        fields.push(form[key]);
                    }
                }
                return fields;
            }
            scope.submit = function () {
                angular.forEach(getFormFields(), function (field) {
                    delete field.$customError;
                });
                form.$submitted = true;
                if (form.$valid) {
                    var p = $q.when(scope.onSubmit());
                    if (p) {
                        p.catch(function (errorData) {
                            form.setErrors(errorData);
                        });
                    }
                }
                else {
                    scope.onValidationError();
                }
            };
        },
    };
}
exports.ovoForm = ovoForm;
