"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var moment = require("moment");
var types_1 = require("../../types");
var booking_flow_service_1 = require("../../services/booking-flow-service/booking-flow-service");
DateSelectionController.$inject = [
    '$scope',
    'SmbRoutesService',
    'smbNavigate',
    '$location',
    'bookingFlowService',
    'SmartMeterBookingService',
    'SmbEventReporter',
    'AppConfig',
    'smbModalService',
    'SmartMeterTypeService',
    '$anchorScroll',
    'optimizelyService',
];
function DateSelectionController($scope, smbRoutesService, navigate, $location, bookingFlowService, smartMeterBookingService, smbEventReporter, AppConfig, smbModalService, smartMeterTypeService, $anchorScroll, optimizelyService) {
    $scope.bookingFlow = bookingFlowService;
    $scope.validationError = null;
    $scope.calendarHorizontalLayout = true;
    $scope.selectedDate = function () { return $scope.bookingFlow.date.value; };
    $scope.$watchCollection('bookingFlow.date', function (newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        $scope.bookingFlow.timeOfDay = null;
        // Show the month of the previously selected date
        $scope.bookingFlow.selectedMonth = $scope.bookingFlow.date.value
            ? $scope.bookingFlow.date.value.clone().startOf('month')
            : null;
    });
    function hasDate(date, dates) {
        if (!date || !dates || dates.length === 0) {
            return false;
        }
        return $scope.selectableDates.some(function (d) { return d.isSame(date); });
    }
    function selectDate() {
        if (!$scope.selectableDates || $scope.selectableDates.length === 0) {
            return;
        }
        var selectedDate = $scope.selectedDate();
        if (!selectedDate || !hasDate(selectedDate, $scope.selectableDates)) {
            $scope.bookingFlow.date.value = $scope.selectableDates[0];
        }
    }
    function getTotalNumberOfSlots(appointmentData) {
        if (!appointmentData) {
            return 0;
        }
        return _.reduce(appointmentData, function (slots, day) { return slots + day.slots.length; }, 0);
    }
    function getInstallationTime() {
        var smartMeterType = smartMeterTypeService.getSmartMeterType();
        return smartMeterType === types_1.SmartTypes.SMETS1 ? '45 minutes' : 'one hour';
    }
    $scope.$watchCollection('bookingFlow.appointmentData', function (newVal) {
        if (!newVal) {
            return;
        }
        var selectableDates = Object.keys(newVal || {}).map(function (dateString) { return moment(dateString, 'YYYY-MM-DD'); });
        $scope.selectableDates = _.sortBy(selectableDates, function (selectableDate) { return selectableDate; });
        selectDate();
    });
    $scope.loadData = function () {
        smbEventReporter.genericEvent('smart-meter-booking-flow-start');
        $scope.bookingFlow.error = null;
        $scope.bookingFlow.loading = true;
        $scope.continueCopy = $scope.bookingFlow.isReschedule ? 'Confirm Booking' : 'Continue';
        $scope.installationTime = getInstallationTime();
        var dateScreenMixpanelEvent = 'smart-meter-booking-date-time-picker';
        return loadConfirmedBookingDetails()
            .then(function (smartMeterBooking) {
            if (!smartMeterBooking || !smartMeterBooking.isBooked) {
                return storeAvailableAppointments().then(function () { return smartMeterBooking; });
            }
            else if ($scope.bookingFlow.isReschedule) {
                return storeAvailableAppointments().then(function () { return smartMeterBooking; });
            }
            else {
                return smartMeterBooking;
            }
        })
            .then(function (smartMeterBooking) {
            if (!smartMeterBooking.isBooked) {
                var totalNumberOfSlots = getTotalNumberOfSlots($scope.bookingFlow.appointmentData);
                smbEventReporter.genericEvent(dateScreenMixpanelEvent, {
                    isReschedule: $scope.bookingFlow.isReschedule,
                    'Number of available appointments': totalNumberOfSlots,
                });
            }
            $scope.calendarHorizontalLayout = optimizelyService.activeVariationIs('SMB - Calendar layout V2', 'horizontal');
            $scope.bookingFlow.loading = false;
        }, function (errorResponse) {
            smbEventReporter.systemError(dateScreenMixpanelEvent, errorResponse.message);
            $scope.bookingFlow.error = 'There was a problem retrieving the available appointments';
            $scope.bookingFlow.loading = false;
        });
    };
    function storeAvailableAppointments() {
        return getAvailableAppointments().then(function (availableAppointments) {
            $scope.bookingFlow.appointmentData = availableAppointments;
        });
    }
    function getAvailableAppointments() {
        return smartMeterBookingService.getAvailableAppointments();
    }
    function loadConfirmedBookingDetails() {
        return smartMeterBookingService
            .getSmartMeterBooking($scope.bookingFlow.smbId)
            .then(function (smartMeterBooking) {
            $scope.bookingFlow.showVulnerabilityQuestion = Boolean(smartMeterBooking.brokerCode);
            if (smartMeterBooking && smartMeterBooking.isBooked) {
                var date = smartMeterBooking.appointmentDate;
                $scope.bookingFlow.confirmedStartDate = moment(date + ' ' + smartMeterBooking.appointmentStartTime, 'YYYY-MM-DD HH:mm');
                $scope.bookingFlow.confirmedEndDate = moment(date + ' ' + smartMeterBooking.appointmentEndTime, 'YYYY-MM-DD HH:mm');
                $scope.bookingFlow.confirmedReference = smartMeterBooking.customerReference;
                $scope.bookingFlow.isProvisional = smartMeterBooking.isProvisional;
                if (!$scope.bookingFlow.isReschedule) {
                    $scope.bookingFlow.isConfirmed = !$scope.bookingFlow.isProvisional;
                    $scope.bookingFlow.timeOfDay = booking_flow_service_1.BookingFlowService.convertToTimeOfDay($scope.bookingFlow.confirmedStartDate);
                }
            }
            return smartMeterBooking;
        })
            .catch(function () { });
    }
    $scope.isAvailableAtTimeOfDay = function (date, timeOfDay) {
        if (!$scope.bookingFlow.appointmentData) {
            return;
        }
        var day = $scope.bookingFlow.appointmentData[date.format('YYYY-MM-DD')];
        if (!day) {
            return false;
        }
        for (var _i = 0, _a = day.slots; _i < _a.length; _i++) {
            var daySlot = _a[_i];
            if (timeOfDay.toString().toLowerCase() === daySlot.band.toLowerCase()) {
                return true;
            }
        }
        return false;
    };
    $scope.selectTimeOfDay = function (timeOfDay) {
        $scope.bookingFlow.timeOfDay = timeOfDay;
        nextStep();
    };
    $scope.continue = function () {
        if (!$scope.bookingFlow.timeOfDay || !$scope.bookingFlow.date.value) {
            $anchorScroll('timeselection');
            return;
        }
        nextStep();
    };
    function nextStep() {
        if ($scope.bookingFlow.isReschedule) {
            // reschedule
            $scope.rescheduleAppointment();
        }
        else {
            // next step
            navigate.to(smbRoutesService.getRoutes().smartMeterData);
        }
    }
    $scope.rescheduleAppointment = function () {
        $scope.bookingFlow.error = null;
        $scope.bookingFlow.loading = true;
        var smartMeterType = smartMeterTypeService.getSmartMeterType();
        smartMeterBookingService
            .rescheduleAppointment($scope.bookingFlow.smbId, {
            date: $scope.bookingFlow.date.value.format('YYYY-MM-DD'),
            timeOfDay: $scope.bookingFlow.timeOfDay,
        }, smartMeterType)
            .then(function (bookingResponse) {
            smbEventReporter.genericEvent('smart-meter-installation-booked', {
                isReschedule: $scope.bookingFlow.isReschedule,
                ihdSelected: $scope.bookingFlow.isIhdSelected,
                smartMeterType: smartMeterType,
            });
            $scope.bookingFlow.isReschedule = false;
            $scope.bookingFlow.isConfirmed = !bookingResponse.isProvisional;
            $scope.bookingFlow.isProvisional = bookingResponse.isProvisional;
            $scope.bookingFlow.loading = false;
            $scope.bookingFlow.confirmedStartDate = moment(bookingResponse.appointmentStartDate, 'YYYY-MM-DD HH:mm');
            $scope.bookingFlow.confirmedEndDate = moment(bookingResponse.appointmentEndDate, 'YYYY-MM-DD HH:mm');
            navigate.to(smbRoutesService.getRoutes().confirmationConfirmation);
        }, function (errorResponse) {
            $scope.bookingFlow.error = 'There was an error rescheduling your appointment. Please try again';
            $scope.bookingFlow.loading = false;
            smbEventReporter.systemError('smart-meter-installation-booking', errorResponse.message);
        });
    };
    $scope.prevPage = function () { return AppConfig.smeBackExitUrl; };
    $scope.popupCalendar = function () {
        $scope.url = 'views/modals/calendar-popup.html';
        $scope.title = 'Select a Date';
        $scope.customFooterUrl = 'views/modals/calendar-footer.html';
        smbModalService.open($scope, 'generic');
    };
    if ($scope.bookingFlow.smbId == null) {
        navigate.to(smbRoutesService.getRoutes().availability);
        return;
    }
    else {
        $scope.loadData().then(function () {
            if (($scope.bookingFlow.isConfirmed || $scope.bookingFlow.isProvisional) && !$scope.bookingFlow.isReschedule) {
                $location.replace();
                $location.url(smbRoutesService.getRoutes().confirmationConfirmation);
            }
        });
    }
}
exports.DateSelectionController = DateSelectionController;
