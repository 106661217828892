"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var moment = require("moment");
var feature_flag_service_1 = require("../../services/feature-flag/feature-flag.service");
SmartMeterConfirmationConfirmationController.$inject = [
    '$scope',
    '$window',
    'SmbRoutesService',
    '$location',
    'bookingFlowService',
    'AppConfig',
    'Device',
    'featureFlag',
    'boltonService',
    'localStorageService',
];
function SmartMeterConfirmationConfirmationController($scope, $window, smbRoutesService, $location, bookingFlowService, AppConfig, Device, featureFlag, boltonService, localStorageService) {
    $scope.bookingFlow = bookingFlowService;
    $scope.smbRoutes = smbRoutesService.getRoutes();
    $scope.isMobile = Device.isMobileSize();
    $scope.myOVOBaseUrl = AppConfig.myOVOBaseUrl;
    $scope.ovoBeyondMember = false;
    $scope.ovoBeyondBanner = true;
    $scope.myOVOLink = AppConfig.myOVOBaseUrl;
    $scope.getIcon = getIcon;
    if (!$scope.bookingFlow.isConfirmed && !$scope.bookingFlow.isProvisional) {
        $location.replace();
        $location.url($scope.smbRoutes.smartMeterBookingStart);
        return;
    }
    setOvoBeyondBanner();
    $scope.googleCalendarUrl = '';
    $scope.googleCalendarUrl = makeGoogleCalendarUrl($scope.bookingFlow.confirmedStartDate, $scope.bookingFlow.confirmedEndDate);
    $scope.isAppointmentInNext24Hours = isAppointmentInNext24Hours($scope.bookingFlow.confirmedStartDate);
    function isAppointmentInNext24Hours(appointmentDate) {
        var today = moment();
        var dayBeforeAppointment = moment(appointmentDate).subtract(1, 'day');
        var MONDAY = 1;
        return ((moment(appointmentDate).day() === MONDAY && !today.isBefore(moment(appointmentDate).day(-2), 'day')) ||
            !today.isBefore(dayBeforeAppointment, 'day'));
    }
    function makeGoogleCalendarUrl(startDateTime, endDateTime) {
        var text = $window.encodeURIComponent('OVO Smart Meter Installation');
        var details = $window.encodeURIComponent('');
        var startDate = startDateTime.utc().format('YYYYMMDD[T]HHmmss[Z]');
        var endDate = endDateTime.utc().format('YYYYMMDD[T]HHmmss[Z]');
        return ('https://www.google.com/calendar/render?action=TEMPLATE' +
            '&text=' +
            text +
            '&dates=' +
            startDate +
            '/' +
            endDate +
            '&details=' +
            details +
            '&location=' +
            '&sf=true&output=xml');
    }
    $scope.rescheduleAppointment = function () {
        $scope.bookingFlow.isReschedule = true;
        $scope.bookingFlow.resetDateTime();
        $location.url($scope.smbRoutes.availability);
    };
    $scope.cancellationPage = function () {
        if ($scope.isAppointmentInNext24Hours) {
            return false;
        }
        else {
            $location.url($scope.smbRoutes.cancellation);
        }
    };
    $scope.printThisPage = function () {
        $window.print();
    };
    function getIcon(iconFile) {
        return AppConfig.assetsBaseUrl + "/icons/" + iconFile;
    }
    if (!$scope.bookingFlow.date || !$scope.bookingFlow.date.value) {
        $scope.bookingFlow.date = { value: $scope.bookingFlow.confirmedStartDate.clone().startOf('day') };
    }
    function setOvoBeyondBanner() {
        boltonService.isOvoBeyondEligible(localStorageService.get('globalAccountId')).then(function (beyondMember) {
            $scope.ovoBeyondBanner = beyondMember && featureFlag.isFeatureActive(feature_flag_service_1.OVO_BEYOND);
        });
    }
    var confirmedCopy = {
        headerText: 'Your booking is confirmed!',
        reminderText: "Here's a reminder of the appointment you've selected:",
        nextStepText: "You've successfully booked your smart meter. You can now track your switch progress:",
    };
    var provisionalCopy = {
        headerText: "We're confirming your booking with the installer",
        reminderText: "Here's a reminder of the appointment you've selected:",
        nextStepText: "We're confirming your booking with our engineers. We'll send you an " +
            'email once it is confirmed. In the meantime, you can track your switch progress:',
    };
    $scope.confirmationCopy = $scope.bookingFlow.isConfirmed ? confirmedCopy : provisionalCopy;
    $scope.bookingFlow.hasElectricalMedicalEquipmentDirty = false;
    $scope.bookingFlow.isIhdSelectedDirty = false;
    $scope.bookingFlow.flexibilityStatusDirty = false;
}
exports.SmartMeterConfirmationConfirmationController = SmartMeterConfirmationConfirmationController;
