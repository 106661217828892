"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var types_1 = require("../../types");
SmartAvailabilityController.$inject = [
    '$scope',
    '$location',
    'smbNavigate',
    'eligibilityService',
    '$q',
    'localStorageService',
    'WanCoverageService',
    'SmbRoutesService',
    'SmartMeterTypeService',
];
function SmartAvailabilityController($scope, $location, navigate, eligibilityService, $q, localStorageService, WanCoverageService, SmbRoutesService, smartMeterTypeService) {
    var smartMeterType;
    this.init = function () {
        var postcode = eligibilityService.getPostcode();
        var jobType = eligibilityService.getJobType();
        if (!postcode || !jobType || !eligibilityService.isMpnAvailable()) {
            $location.replace();
            navigate.to(SmbRoutesService.getRoutes().credit);
            return;
        }
        function checkAvailability(smartTypes) {
            if (!eligibilityService.isMpnAvailable()) {
                return $q.when({ availability: undefined });
            }
            smartMeterType = smartTypes[0];
            var deferred = $q.defer();
            eligibilityService
                .getAppointmentAvailability(smartMeterType)
                .then(function (data) {
                eligibilityService.setAvailability(smartMeterType, data.availability);
                if (_.keys(data.availableAppointments).length > 0 || smartTypes.length === 1) {
                    deferred.resolve(data);
                }
                else {
                    var smartMeterTypes = smartTypes.slice(1, smartTypes.length);
                    deferred.resolve(checkAvailability(smartMeterTypes));
                }
            })
                .catch(function () {
                deferred.resolve({ availability: undefined });
            });
            return deferred.promise;
        }
        function getAvailableAppointments() {
            var availabilitySmartTypes = getAvailabilitySmartTypes();
            checkAvailability(availabilitySmartTypes).then(function (data) {
                localStorageService.set('availableAppointments', data.availableAppointments);
                $scope.availability = data.availability;
                onComplete();
            }, onComplete);
        }
        function wanCoverageUnknown() {
            return localStorageService.get('wanCoverage') === 'Unknown' || !localStorageService.get('wanCoverage');
        }
        var isWanCheckEnabled = AppConfig.isWanCheckEnabled;
        if (isWanCheckEnabled && wanCoverageUnknown()) {
            WanCoverageService.pollWanCoverage().then(function () {
                getAvailableAppointments();
            });
        }
        else {
            getAvailableAppointments();
        }
    };
    function getAvailabilitySmartTypes() {
        if (!hasWanCoverage()) {
            return [types_1.SmartTypes.SMETS1];
        }
        if (getSmartType() === types_1.SmartTypes.SMETS2) {
            return [types_1.SmartTypes.SMETS2, types_1.SmartTypes.SMETS1];
        }
        return [types_1.SmartTypes.SMETS1, types_1.SmartTypes.SMETS2];
    }
    function hasWanCoverage() {
        var wanCoverage = localStorageService.get('wanCoverage');
        var isWanCheckEnabled = AppConfig.isWanCheckEnabled;
        return isWanCheckEnabled && wanCoverage === 'HasCoverage';
    }
    function getSmartType() {
        var smartType = smartMeterTypeService.getSmartMeterType();
        if (smartType) {
            return smartType;
        }
        if (hasWanCoverage()) {
            return types_1.SmartTypes.SMETS2;
        }
        return types_1.SmartTypes.SMETS1;
    }
    function onComplete() {
        smartMeterTypeService.setSmartMeterType(smartMeterType);
        localStorageService.set('fuelType', eligibilityService.isDualFuel() ? 'DualFuel' : 'SingleFuel');
        if ($scope.availability === 'High' || $scope.availability === 'Low') {
            navigate.to(SmbRoutesService.getRoutes().smartMeterBookingSetup);
        }
    }
    this.init();
}
exports.SmartAvailabilityController = SmartAvailabilityController;
