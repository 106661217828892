"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var constants = {
    ViewedScreen: 'viewed-screen',
    SystemError: 'system-error',
    GenericEvent: 'generic-event',
};
var SmbEventReporter = /** @class */ (function () {
    function SmbEventReporter($route) {
        this.$route = $route;
        this.listeners = [];
    }
    SmbEventReporter.prototype.raise = function (eventType, eventName, properties) {
        this.listeners.forEach(function (listener) {
            listener(eventType, eventName, properties);
        });
    };
    SmbEventReporter.prototype.viewedScreen = function (properties, screenName) {
        properties = properties ? properties : {};
        screenName = screenName ? screenName : this.$route.current.$$route.originalPath.replace(/^\//, '');
        screenName = screenName ? screenName : 'home';
        this.raise(constants.ViewedScreen, screenName, properties);
    };
    SmbEventReporter.prototype.systemError = function (errorType, error, properties) {
        properties = properties ? properties : {};
        properties = _.extend(properties, { Error: error });
        this.raise(constants.SystemError, errorType, properties);
    };
    SmbEventReporter.prototype.genericEvent = function (eventName, properties) {
        properties = properties ? properties : {};
        this.raise(constants.GenericEvent, eventName, properties);
    };
    SmbEventReporter.prototype.addListener = function (listener) {
        this.listeners.push(listener);
    };
    SmbEventReporter.$inject = ['$route'];
    return SmbEventReporter;
}());
exports.SmbEventReporter = SmbEventReporter;
