"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
SmartMeterConfirmationController.$inject = [
    '$scope',
    'smbNavigate',
    'SmbRoutesService',
    '$location',
    'bookingFlowService',
    'SmartMeterBookingService',
    'SmbEventReporter',
    'AppConfig',
    'formContentService',
    'smbModalService',
    '$anchorScroll',
    'SmartMeterTypeService',
    'localStorageService',
];
function SmartMeterConfirmationController($scope, navigate, smbRoutesService, $location, bookingFlowService, smartMeterBookingService, smbEventReporter, AppConfig, formContentService, smbModalService, $anchorScroll, smartMeterTypeService, localStorageService) {
    $scope.bookingFlow = bookingFlowService;
    $scope.bookingFlow.error = null;
    $scope.smbRoutes = smbRoutesService.getRoutes();
    $scope.vulnerabilityCodes = formContentService.vulnerabilityCodes;
    $scope.bookingFlow.loading = false;
    if (!$scope.bookingFlow.smbId) {
        $location.replace();
        $location.url(AppConfig.smbSetupUrl);
    }
    if ($scope.bookingFlow.appointmentData && ($scope.bookingFlow.isConfirmed || $scope.bookingFlow.isProvisional)) {
        $location.replace();
        $location.url($scope.smbRoutes.confirmationConfirmation);
    }
    if (!$scope.bookingFlow.appointmentData) {
        $location.replace();
        $location.url($scope.smbRoutes.smartMeterBookingStart);
        // todo go back through our history to remove all SMB histories
        // otherwise when we eventually go to this page again, the transition is wrong
    }
    $scope.confirmBooking = function (isDataEntered) {
        if (!isDataEntered) {
            $anchorScroll('medical-equipment');
            return;
        }
        $scope.bookingFlow.error = null;
        $scope.bookingFlow.loading = true;
        var dataOptIn = ($scope.bookingFlow.smartData === 'halfHour'
            ? 'HalfHourly'
            : $scope.bookingFlow.smartData === 'monthly'
                ? 'Monthly'
                : 'Daily');
        var slot = {
            date: $scope.bookingFlow.date.value.format('YYYY-MM-DD'),
            timeOfDay: $scope.bookingFlow.timeOfDay,
        };
        var smartMeterType = smartMeterTypeService.getSmartMeterType();
        var bookAppointmentRequest = {
            smbId: $scope.bookingFlow.smbId,
            slot: slot,
            contactName: $scope.bookingFlow.homeDetails.name,
            contactPhoneNumber: $scope.bookingFlow.homeDetails.phone || '',
            contactEmailAddress: $scope.bookingFlow.homeDetails.email || '',
            parkingInformation: $scope.bookingFlow.access.parking || '',
            isMeterBlocked: $scope.bookingFlow.isBlocked === 'true',
            isIhdSelected: $scope.bookingFlow.isIhdSelected,
            hasElectricalMedicalEquipment: $scope.bookingFlow.hasElectricalMedicalEquipment === 'true',
            vulnerabilityCode: $scope.bookingFlow.showVulnerabilityQuestion ? $scope.bookingFlow.vulnerabilityCode : '',
            otherInformation: $scope.bookingFlow.access.other || '',
            dataOptIn: dataOptIn,
            flexibleStatus: $scope.bookingFlow.flexibilityStatus,
            smartMeterType: smartMeterType,
            billingSystem: 'Gentrack',
        };
        smartMeterBookingService
            .bookAppointment(bookAppointmentRequest)
            .then(function () {
            var wanCoverage = localStorageService.get('wanCoverage');
            smbEventReporter.genericEvent('smart-meter-installation-booked', {
                isReschedule: $scope.bookingFlow.isReschedule,
                ihdSelected: $scope.bookingFlow.isIhdSelected,
                smartMeterType: smartMeterType,
                wanCoverage: wanCoverage,
            });
            $scope.bookingFlow.loading = false;
            if (!$scope.bookingFlow.date || !$scope.bookingFlow.date.value) {
                $scope.bookingFlow.date = { value: $scope.bookingFlow.confirmedStartDate.clone().startOf('day') };
            }
            $scope.bookingFlow.hasElectricalMedicalEquipmentDirty = true;
            navigate.to($scope.smbRoutes.confirmationConfirmation);
        })
            .catch(function (errorResponse) {
            if (errorResponse.code === 'AppointmentUnavailable') {
                $scope.title = 'Please select a different appointment';
                $scope.url = 'views/modals/appointment-unavailable.html';
                $scope.customFooterUrl = 'views/modals/appointment-unavailable-footer.html';
                smbModalService.open($scope, 'generic');
            }
            else {
                var telephoneNumber = '0330 303 5063';
                $scope.bookingFlow.error = "There's been an error booking your smart meter. Please give us a call on " + telephoneNumber + ".";
            }
            $scope.bookingFlow.loading = false;
            smbEventReporter.systemError('smart-meter-installation-booking', errorResponse.message);
        });
    };
    $scope.prevPage = function () { return $scope.smbRoutes.homeDetails; };
    $scope.showRearrangementTerms = function () {
        $scope.title = 'What if I need to rearrange?';
        $scope.url = 'views/modals/rearrangement-terms.html';
        smbModalService.open($scope, 'generic');
    };
    $scope.editInstallationDate = function () {
        navigate.to($scope.smbRoutes.smartMeterBookingStart);
        smbModalService.close();
    };
    smbEventReporter.viewedScreen();
    $anchorScroll();
}
exports.SmartMeterConfirmationController = SmartMeterConfirmationController;
