"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
WanCoverageService.$inject = ['$q', 'localStorageService', '$timeout', 'SmbService'];
function WanCoverageService($q, localStorageService, $timeout, SmbService) {
    var WAN_COVERAGE_POLLING_LIMIT = 5;
    function pollWanCoverage() {
        var deferred = $q.defer();
        function poll(tries) {
            var smbId = localStorageService.get('smbId');
            SmbService.getWanCoverage(smbId)
                .then(function (response) {
                var wanCoverage = response.wanCoverage;
                if (wanCoverage === 'HasCoverage' || wanCoverage === 'NoCoverage') {
                    localStorageService.set('wanCoverage', response.wanCoverage);
                    deferred.resolve();
                }
                else if (tries === WAN_COVERAGE_POLLING_LIMIT) {
                    deferred.resolve();
                }
                else {
                    $timeout(function () { return poll(tries + 1); }, 2000);
                }
            })
                .catch(function () { return deferred.resolve(); });
        }
        poll(0);
        return deferred.promise;
    }
    return {
        pollWanCoverage: pollWanCoverage,
    };
}
exports.WanCoverageService = WanCoverageService;
