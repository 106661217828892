"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
CancellationConfig.$inject = ['$routeProvider'];
function CancellationConfig($routeProvider) {
    var routeCancel = {};
    routeCancel.templateUrl = 'views/cancellation/cancellation.html';
    routeCancel.controller = 'SmartMeterCancellationController';
    $routeProvider.when('/smart-meter-booking/cancellation', routeCancel);
    var routeCancellationConfirmation = {};
    routeCancellationConfirmation.templateUrl = 'views/cancellation/cancellation-confirmation.html';
    routeCancellationConfirmation.controller = 'SmartMeterCancellationConfirmationController';
    $routeProvider.when('/smart-meter-booking/cancellation-confirmation', routeCancellationConfirmation);
}
exports.CancellationConfig = CancellationConfig;
