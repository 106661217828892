"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
AlreadySmartConfig.$inject = ['$routeProvider'];
function AlreadySmartConfig($routeProvider) {
    var routeAlreadySmart = {};
    routeAlreadySmart.templateUrl = 'views/already-smart/already-smart.html';
    routeAlreadySmart.controller = 'AlreadySmartController';
    $routeProvider.when('/already-smart', routeAlreadySmart);
}
exports.AlreadySmartConfig = AlreadySmartConfig;
