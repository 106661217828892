"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var angular = require("angular");
var cancellation_1 = require("./cancellation");
var cancellation_confirmation_1 = require("./cancellation-confirmation");
var cancellation_routing_1 = require("./cancellation-routing");
angular
    .module('ovo.smb.cancellation', ['ngRoute'])
    .config(cancellation_routing_1.CancellationConfig)
    .controller('SmartMeterCancellationController', cancellation_1.SmartMeterCancellationController)
    .controller('SmartMeterCancellationConfirmationController', cancellation_confirmation_1.SmartMeterCancellationConfirmationController);
