"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var URI = require("urijs");
Run.$inject = ['$rootScope', 'AppConfig', 'optimizelyService', '$location', 'smbNavigate', 'SmbRoutesService'];
function Run($rootScope, AppConfig, optimizelyService, $location, navigate, SmbRoutesService) {
    optimizelyService.triggerPageChanges();
    $rootScope.assetsBaseUrl = AppConfig.assetsBaseUrl;
    $rootScope.isSmets2Enabled = AppConfig.isWanCheckEnabled;
    $rootScope.navigatingToExternalRedirectUrl = false;
    function getEndOfPath(path) {
        return URI.parse(URI.decode(path)).path;
    }
    $rootScope.$on('$locationChangeStart', function (_event, next, current) {
        if (SmbRoutesService.isLocationPartOfBookingFlow(getEndOfPath(current)) &&
            SmbRoutesService.isLocationSmbSetup(getEndOfPath(next))) {
            navigate.to(SmbRoutesService.getRoutes().availability);
        }
    });
    window.addEventListener('beforeunload', function (e) {
        var currentLocation = $location.path();
        if (SmbRoutesService.isLocationPartOfBookingFlow(currentLocation) && !$rootScope.navigatingToExternalRedirectUrl) {
            var confirmationMessage = 'Are you sure you don’t want to book your smart meter installation date?';
            (e || window.event).returnValue = confirmationMessage; // Gecko + IE
            return confirmationMessage; // Gecko + Webkit, Safari, Chrome etc.
        }
    });
}
exports.Run = Run;
