"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
AccountServiceConfig.$inject = ['$routeProvider'];
function AccountServiceConfig($routeProvider) {
    var routeAccountService = {};
    routeAccountService.templateUrl = 'views/account-service/account-service.html';
    routeAccountService.controller = 'AccountServiceController';
    $routeProvider.when('/account-service', routeAccountService);
}
exports.AccountServiceConfig = AccountServiceConfig;
