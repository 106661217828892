"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SmartMeterBookingService = /** @class */ (function () {
    function SmartMeterBookingService(AppConfig, $rootScope, HttpClient, localStorageService, $q) {
        this.AppConfig = AppConfig;
        this.$rootScope = $rootScope;
        this.HttpClient = HttpClient;
        this.localStorageService = localStorageService;
        this.$q = $q;
        this.bookingClient = new this.HttpClient(this.AppConfig.smbServiceBaseUrl);
    }
    SmartMeterBookingService.prototype.getAvailableAppointments = function () {
        return this.$q.when(this.localStorageService.get('availableAppointments'));
    };
    SmartMeterBookingService.prototype.bookAppointment = function (appointmentBookingRequest) {
        var _this = this;
        appointmentBookingRequest.userId = 'DEPRECATED';
        return this.bookingClient.post('/appointment-booking', appointmentBookingRequest, function (data) {
            _this.$rootScope.$emit('smartMeterBooked');
            return data;
        });
    };
    SmartMeterBookingService.prototype.rescheduleAppointment = function (smbId, rescheduleSlot, smartMeterType) {
        var _this = this;
        var rescheduleAppointmentRequest = { slot: rescheduleSlot, smartMeterType: smartMeterType };
        return this.bookingClient.put("/" + smbId + "/appointment-booking", rescheduleAppointmentRequest, function (data) {
            _this.$rootScope.$emit('smartMeterBooked');
            return data;
        });
    };
    SmartMeterBookingService.prototype.cancelAppointment = function (smbId) {
        var _this = this;
        return this.bookingClient.delete("/" + smbId + "/appointment-booking", function (data) {
            _this.$rootScope.$emit('smartMeterCancelled');
            return data;
        });
    };
    SmartMeterBookingService.prototype.getSmartMeterBooking = function (smbId) {
        return this.bookingClient.get("/" + smbId);
    };
    SmartMeterBookingService.$inject = ['AppConfig', '$rootScope', 'HttpClient', 'localStorageService', '$q'];
    return SmartMeterBookingService;
}());
exports.SmartMeterBookingService = SmartMeterBookingService;
