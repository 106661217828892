"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var NavigateService = /** @class */ (function () {
    function NavigateService($location, $window) {
        this.$location = $location;
        this.$window = $window;
    }
    NavigateService.prototype.to = function (path) {
        this.$location.url(path);
    };
    NavigateService.prototype.url = function () {
        return this.$location.path();
    };
    NavigateService.prototype.toExternalUrl = function (url) {
        this.$window.location.href = url;
    };
    NavigateService.$inject = ['$location', '$window'];
    return NavigateService;
}());
exports.NavigateService = NavigateService;
