"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
MissingMeterDetailsConfig.$inject = ['$routeProvider'];
function MissingMeterDetailsConfig($routeProvider) {
    var routeMissingMeterDetails = {};
    routeMissingMeterDetails.templateUrl = 'views/missing-meter-details/missing-meter-details.html';
    routeMissingMeterDetails.controller = 'MissingMeterDetailsController';
    $routeProvider.when('/missing-meter-details', routeMissingMeterDetails);
}
exports.MissingMeterDetailsConfig = MissingMeterDetailsConfig;
