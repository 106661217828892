"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
BookingFlowStateFactory.$inject = ['localStorageService'];
function BookingFlowStateFactory(localStorageService) {
    function fetchFromLocalStorage() {
        return (localStorageService.get('bookingFlowState') || {
            customerName: null,
            emailAddress: null,
            phoneNumber: null,
            addressDetails: null,
            eligibilityInfo: null,
            availability: null,
            fuelType: null,
            planType: {},
            accountId: null,
            mpan: null,
            mprn: null,
            elecMsn: null,
            gasMsn: null,
            hasTwoRateElectricityTariff: false,
        });
    }
    function update(name, value) {
        var localStorageObject = fetchFromLocalStorage();
        localStorageObject[name] = value;
        localStorageService.set('bookingFlowState', localStorageObject);
    }
    function getProperty(name) {
        return {
            set: function (value) {
                update(name, value);
            },
            get: function () {
                return fetchFromLocalStorage()[name];
            },
        };
    }
    return {
        customerName: getProperty('customerName'),
        emailAddress: getProperty('emailAddress'),
        phoneNumber: getProperty('phoneNumber'),
        addressDetails: getProperty('addressDetails'),
        eligibilityInfo: getProperty('eligibilityInfo'),
        availability: getProperty('availability'),
        fuelType: getProperty('fuelType'),
        planType: getProperty('planType'),
        accountId: getProperty('accountId'),
        mpan: getProperty('mpan'),
        mprn: getProperty('mprn'),
        elecMsn: getProperty('elecMsn'),
        gasMsn: getProperty('gasMsn'),
        hasTwoRateElectricityTariff: getProperty('hasTwoRateElectricityTariff'),
    };
}
exports.BookingFlowStateFactory = BookingFlowStateFactory;
