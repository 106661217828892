"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
smbFlowHeaderDirective.$inject = ['smbNavigate', 'SmbRoutesService'];
function smbFlowHeaderDirective(navigate, SmbRoutesService) {
    return {
        restrict: 'E',
        templateUrl: 'components/smb-flow-header/smb-flow-header.html',
        scope: {
            activeStepNum: '=',
            headerText: '=',
        },
        link: function (scope) {
            var maxStepNum = 4;
            scope.completedStepNum = 5;
            var completeSteps = scope.activeStepNum - 1;
            var incompleteSteps = scope.activeStepNum === scope.completedStepNum ? 0 : maxStepNum - scope.activeStepNum;
            scope.completeStepNums = [];
            for (var i = 1; i <= completeSteps; i++) {
                scope.completeStepNums.push(i);
            }
            scope.incompleteStepNums = [];
            for (var i = maxStepNum - incompleteSteps + 1; i <= maxStepNum; i++) {
                scope.incompleteStepNums.push(i);
            }
            scope.navigateToStep = function (stepNum) {
                var smbRoutes = SmbRoutesService.getRoutes();
                switch (stepNum) {
                    case 1:
                        navigate.to(smbRoutes.smartMeterBookingStart);
                        break;
                    case 2:
                        navigate.to(smbRoutes.smartMeterData);
                        break;
                    case 3:
                        navigate.to(smbRoutes.homeDetails);
                        break;
                    case 4:
                        navigate.to(smbRoutes.confirmation);
                        break;
                }
            };
        },
    };
}
exports.smbFlowHeaderDirective = smbFlowHeaderDirective;
