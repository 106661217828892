"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var moment = require("moment");
SmartMeterCancellationController.$inject = [
    '$scope',
    'SmbRoutesService',
    'bookingFlowService',
    'SmartMeterBookingService',
    'smbNavigate',
    'SmbEventReporter',
    'smbModalService',
];
function SmartMeterCancellationController($scope, smbRoutesService, bookingFlowService, smartMeterBookingService, navigate, smbEventReporter, smbModalService) {
    $scope.bookingFlow = bookingFlowService;
    $scope.cancellation = { reasonCode: '', customReason: '' };
    $scope.cancellationReasons = getCancellationReasons();
    smbEventReporter.viewedScreen();
    $scope.cancelAppointment = function (isDataSet) {
        if (!isDataSet) {
            return;
        }
        $scope.cancelling = true;
        smartMeterBookingService.cancelAppointment($scope.bookingFlow.smbId).then(function () {
            smbEventReporter.genericEvent('smart-meter-installation-cancelled', getCancellationEventProperties($scope.cancellation));
            $scope.cancelling = false;
            $scope.bookingFlow.isConfirmed = false;
            $scope.bookingFlow.isProvisional = false;
            $scope.bookingFlow.resetDateTime();
            navigate.to(smbRoutesService.getRoutes().cancellationConfirmation);
        }, function (errorResponse) {
            $scope.cancelling = false;
            $scope.cancelError = errorResponse.message;
            smbEventReporter.systemError('smart-meter-installation-cancellation', errorResponse.message);
        });
    };
    $scope.viewBooking = function () { return navigate.to(smbRoutesService.getRoutes().confirmationConfirmation); };
    $scope.rescheduleAppointment = function () {
        $scope.bookingFlow.isReschedule = true;
        $scope.bookingFlow.resetDateTime();
        smbModalService.close();
        navigate.to(smbRoutesService.getRoutes().availability);
    };
    $scope.prevPage = function () { return smbRoutesService.getRoutes().confirmationConfirmation; };
    $scope.resetCustomReason = function () {
        $scope.cancellation.customReason = '';
    };
    function openRebookModal() {
        $scope.title = 'Would you like to rearrange your install?';
        $scope.url = 'views/modals/rebook-popup.html';
        $scope.customFooterUrl = 'views/modals/rebook-footer.html';
        smbModalService.open($scope, 'generic');
    }
    function getCancellationEventProperties(cancellation) {
        var daysUntilBooking = $scope.bookingFlow.confirmedStartDate.diff(moment(), 'days');
        var eventProperties = {
            'Days Until Booking': daysUntilBooking,
            'Cancellation Reason': cancellation.reasonCode,
        };
        if (cancellation.reasonCode === 'Other') {
            eventProperties['Cancellation Custom Reason'] = cancellation.customReason;
        }
        return eventProperties;
    }
    function getCancellationReasons() {
        return [
            { reasonCode: 'CantMakeTime', description: "I can't make this time any longer.", action: openRebookModal },
            { reasonCode: 'MovingHome', description: "I'm moving to a new home." },
            { reasonCode: 'DontNeedSmartMeter', description: "I don't think I need a smart meter." },
            { reasonCode: 'SwitchingProvider', description: "I'm switching energy provider." },
            { reasonCode: 'Other', description: 'Other:' },
        ];
    }
}
exports.SmartMeterCancellationController = SmartMeterCancellationController;
