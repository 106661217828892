"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var banner_1 = require("./banner");
function bannerTermsDirective() {
    return {
        restrict: 'E',
        controller: banner_1.bannerController,
        templateUrl: 'components/banner/terms.html',
    };
}
exports.bannerTermsDirective = bannerTermsDirective;
