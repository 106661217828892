"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var angular = require("angular");
var identity_1 = require("./identity");
var identity_routing_1 = require("./identity-routing");
var sign_in_form_component_1 = require("./sign-in-form/sign-in-form.component");
var steps_info_component_1 = require("./steps-info/steps-info.component");
angular
    .module('ovo.smb.indentity', ['ngRoute'])
    .config(identity_routing_1.IdentityConfig)
    .controller('IdentityController', identity_1.IdentityController)
    .component('signInForm', sign_in_form_component_1.signInFormComponent)
    .component('stepsInfo', steps_info_component_1.stepsInfoComponentConfig);
