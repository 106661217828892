"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
DateSelectionConfig.$inject = ['$routeProvider'];
function DateSelectionConfig($routeProvider) {
    var routeStart = {};
    routeStart.templateUrl = 'views/date-selection/date-selection.html';
    routeStart.controller = 'DateSelectionController';
    $routeProvider.when('/smart-meter-booking/start', routeStart);
}
exports.DateSelectionConfig = DateSelectionConfig;
