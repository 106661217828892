"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var angular = require("angular");
require("./account-service/account-service.module");
require("./already-smart/already-smart.module");
require("./appointment-details/appointment-details.module");
require("./availability/availability.module");
require("./cancellation/cancellation.module");
require("./confirmation/confirmation.module");
require("./date-selection/date-selection.module");
require("./identity/identity.module");
require("./home-details/home-details.module");
require("./eligibility/eligibility.module");
require("./missing-meter-details/missing-meter-details.module");
require("./property-questions/property-questions.module");
require("./smart-meter-booking-setup/smart-meter-booking-setup.module");
require("./smart-meter-data/smart-meter-data.module");
angular.module('ovo.smb.views', [
    'ovo.smb.accountService',
    'ovo.smb.alreadySmart',
    'ovo.smb.appointmentDetails',
    'ovo.smb.availability',
    'ovo.smb.cancellation',
    'ovo.smb.confirmation',
    'ovo.smb.dateSelection',
    'ovo.smb.indentity',
    'ovo.smb.homeDetails',
    'ovo.smb.eligibility',
    'ovo.smb.missingMeterDetails',
    'ovo.smb.propertyQuestions',
    'ovo.smb.smartMeterBookingSetup',
    'ovo.smb.smartMeterData',
]);
