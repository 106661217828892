"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.signInFormComponent = {
    templateUrl: 'views/identity/sign-in-form/sign-in-form.html',
    bindings: {
        data: '<',
        identityError: '<',
        onSubmit: '&',
        loading: '<',
        showAccountId: '<',
    },
};
