"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
SmbMixpanelFactory.$inject = ['mixpanel', 'SmbEventReporter'];
function SmbMixpanelFactory(mixpanel, SmbEventReporter) {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    function noop() { }
    function setup() {
        SmbEventReporter.addListener(raiseSmbMixpanelEvent);
    }
    function raiseSmbMixpanelEvent(eventType, eventName, properties) {
        (({
            'viewed-screen': function () {
                (({
                    'smart-meter-booking': function () {
                        mixpanel.track('SMB - Viewed Date Picker Screen', properties);
                    },
                    'smart-meter-booking/smart-meter-data': function () {
                        mixpanel.track('SMB - Viewed Data Opt In And IHD Screen', properties);
                    },
                    'smart-meter-booking/home-details': function () {
                        mixpanel.track('SMB - Viewed Personal Details And Meter Access Screen', properties);
                    },
                    'smart-meter-booking/confirmation': function () {
                        mixpanel.track('SMB - Viewed Confirm Booking Screen', properties);
                    },
                    'smart-meter-booking/cancellation': function () {
                        mixpanel.track('SMB - Viewed Cancellation Information Screen', properties);
                    },
                }[eventName] || noop)());
            },
            'system-error': function () {
                (({
                    'smart-meter-installation-booking': function () {
                        mixpanel.track('SMB - Smart Meter Installation Booking Error', properties);
                    },
                    'smart-meter-installation-cancellation': function () {
                        mixpanel.track('SMB - Smart Meter Installation Cancellation Error', properties);
                    },
                    'smart-meter-booking-date-picker': function () {
                        mixpanel.track('SMB - Viewed Date Picker Screen Error', properties);
                    },
                    'smart-meter-booking-date-time-picker': function () {
                        mixpanel.track('SMB - Viewed Date And Time Picker Screen Error', properties);
                    },
                }[eventName] || noop)());
            },
            'generic-event': function () {
                (({
                    'smart-meter-booking-flow-start': function () {
                        mixpanel.track('SMB - Smart Meter Booking Flow Start', properties);
                    },
                    'smart-meter-installation-booked': function () {
                        mixpanel.people.set({ 'Smart Meter Booked': true });
                        mixpanel.track('SMB - Smart Meter Installation Booked', properties);
                    },
                    'smart-meter-booking-date-picker': function () {
                        mixpanel.track('SMB - Viewed Date Picker Screen', properties);
                    },
                    'smart-meter-booking-time-slot-picker': function () {
                        mixpanel.track('SMB - Viewed Time Slot Picker Screen', properties);
                    },
                    'smart-meter-booking-date-time-picker': function () {
                        mixpanel.track('SMB - Viewed Date And Time Picker Screen', properties);
                    },
                    'smart-meter-installation-cancelled': function () {
                        mixpanel.people.set({ 'Smart Meter Booked': false });
                        mixpanel.track('SMB - Smart Meter Installation Cancelled', properties);
                    },
                }[eventName] || noop)());
            },
        }[eventType] || noop)());
    }
    return {
        setup: setup,
        raiseSmbMixpanelEvent: raiseSmbMixpanelEvent,
    };
}
exports.SmbMixpanelFactory = SmbMixpanelFactory;
