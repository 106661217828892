"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var uuidv4 = require("uuid/v4");
var TraceTokenService = /** @class */ (function () {
    function TraceTokenService() {
    }
    TraceTokenService.prototype.getCurrentTraceToken = function () {
        if (!this.currentTraceToken) {
            this.currentTraceToken = uuidv4();
        }
        return this.currentTraceToken;
    };
    return TraceTokenService;
}());
exports.TraceTokenService = TraceTokenService;
